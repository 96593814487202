@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;
  height: 100%;
  padding: 20px 0px 20px 0px;

  &__topHeading {
    @include customTags();
  }

  &__innerContainer {
    @include flexProperties(row, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
    &__rightContainer {
      @include flexProperties(row, flex-start, flex-start, $fullWidth: false);
      width: 50%;
      height: 100%;
      flex-wrap: wrap;
      &__infoContainer {
        @include flexProperties(row, flex-start, center, $fullWidth: false);
        gap: 10px;
        width: 50% !important;
        &__streams {
          font-size: 20px !important;
          font-weight: 100 !important;
          color: #fff;
        }
        &__streamsSecondary {
          font-size: 10px !important;
          font-weight: 400 !important;
          color: #fff;
          white-space: nowrap;
        }
      }
    }
    &__leftContainer {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 50%;
      height: 100%;
      // background-color: coral;
    }
  }

  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}
.apexcharts-tooltip {
  background-color: #add8e6 !important; /* Your desired background color */
}

@media (min-width: 601px) and (max-width: 700px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 801px) and (max-width: 900px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 901px) and (max-width: 1000px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 18px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1001px) and (max-width: 1999px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1401px) and (max-width: 1535px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1536px) and (max-width: 1635px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1636px) and (max-width: 1735px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1736px) and (max-width: 1835px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 18px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1836px) and (max-width: 1935px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 20px !important;
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 750px) {
//   .page {
//     &__innerContainer {
//       &__rightContainer {
//         &__infoContainer {
//           &__streams {
//             font-size: 16px !important;
//           }
//         }
//       }
//     }
//   }
// }
