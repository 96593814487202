@import "../../../../styles/scss.scss";
@import "../../../../styles/colors.scss";

.page {
  width: 100%;
  padding: 0px 20px;
  &__fileUpload {
    display: flex;
    align-items: center;
    gap: 10px;
    div:nth-child(2) {
      width: 100%;
    }

    &__text {
      color: var(--base-tags-color) !important;
      font-size: 12px !important;
      margin-bottom: 10px !important;
    }

    &__input {
      &__btn_disable {
        background-color: transparent !important;
        color: var(--base-deactive-button-background-color) !important;
        border-radius: 12px !important;
        border: 2px dashed var(--base-deactive-button-background-color) !important;
        width: 100% !important;
        font-size: 14px !important;
        height: 40px;
        white-space: nowrap !important;
        svg {
          width: 20px;
          height: 20px;
          margin-top: 10px;
          fill: var(--base-deactive-button-background-color) !important;
        }
      }
      &__btn {
        background-color: transparent !important;
        // background-color: var(--base-button-background-color) !important;
        color: var(--base-button-background-color) !important;
        border-radius: 12px !important;
        width: 100% !important;
        border: 2px dashed var(--base-button-background-color) !important;
        font-size: 14px !important;
        height: 40px;
        svg {
          width: 20px;
          height: 20px;
          margin-top: 10px;
          fill: var(--base-icon-color) !important;
        }
        &:hover {
          background-color: none;
        }
      }
      &__btn_available {
        padding: 18px 10px !important;
        background-color: var(--base-button-background-color) !important;
        color: var(--base-button-text-color) !important;
        border-radius: 12px !important;
        width: 100% !important;
        border: 2px solid var(--base-button-background-color) !important;
        font-size: 14px !important;
        height: 40px;
        padding: 100px auto !important;
        font-weight: bold !important;
        svg {
          width: 20px;
          height: 20px;
          margin-top: 10px;
          fill: var(--base-action-icon-color) !important;
        }
        &:hover {
          background-color: none;
        }
        &__text {
          @include textEllipsis(150px);
        }
      }
    }

    &__uploadBtn,
    &__not_uploadBtn {
      border-radius: 12px !important;
      color: var(--base-button-text-color) !important;
      width: 100%;
      height: 40px;
      padding: 10px 20px !important;
      font-size: 14px !important;
      font-weight: bold !important;
      white-space: nowrap;
      svg {
        fill: var(--base-action-icon-color);
      }
    }

    &__uploadBtn {
      background-color: var(--base-button-background-color) !important;
      &:hover {
        background-color: var(--base-button-background-color) !important;
      }
    }

    &__not_uploadBtn {
      background-color: var(--base-deactive-button-background-color) !important;
      &:hover {
        background-color: var(
          --base-deactive-button-background-color
        ) !important;
      }
    }

    &__select_icon {
      color: var(--base-text-color) !important;
      font-size: 30px !important;
      font-weight: bold;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  &__accordians {
    margin-top: 30px;
    &__container {
      width: 100%;
      color: var(--accordian-contianer-text);
      background-color: var(--accordian-contianer-background) !important;
      margin-bottom: 10px !important;
      border: 1px solid var(--accordian-contianer-border) !important;
      border-radius: 12px !important;
      overflow: hidden !important;

      &__summary {
        background-color: var(--accordian-contianer-background);
        color: var(--accordian-contianer-text);
        overflow: hidden;
        justify-content: space-between;
        align-items: center;
        &__name {
          color: var(--base-heading-color) !important;
          font-size: 14px !important;
          margin-top: 4px !important;
        }
        &__icons {
          display: flex !important;
          justify-content: flex-end !important;
          align-items: center !important;
          width: 100% !important;
          gap: 10px;
          margin-right: 10px;
          &__upDown {
            background-color: var(--base-action-icon-background) !important;
            width: 30px;
            height: 30px;
            &:hover {
              background-color: var(--base-action-icon-background) !important;
            }
            svg {
              fill: var(--base-action-icon-color);
            }
          }
          &__delete {
            background-color: var(
              --base-action-icon-delete-background
            ) !important;
            width: 30px;
            height: 30px;
            &:hover {
              background-color: var(
                --base-action-icon-delete-background
              ) !important;
            }
            svg {
              fill: var(--base-action-icon-color);
            }
          }
          &__download {
            background-color: var(--base-action-icon-background) !important;

            width: 30px;
            height: 30px;
            &:hover {
              background-color: var(--base-action-icon-background) !important;
            }
            svg {
              fill: var(--base-action-icon-color);
            }
          }
        }
      }

      &__details {
        padding: 0px 0px 20px 0px !important;
        &__amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background-color: var(--base-container-backgroung-color) !important;
          margin-top: 10px;
          padding: 15px 20px;
          border-radius: 12px;
          &__year {
            color: var(--base-tags-color) !important;
            font-size: 16px !important;
            // margin-top: 5px;
          }
          &__value {
            color: var(--base-heading-color) !important;
            font-size: 16px !important;
            font-weight: bold !important;
          }
        }
      }
    }
  }

  &__loader {
    // @include flexProperties(column, flex-start, flex-start, $fullWidth: false);
    width: 25px !important;
    height: 25px;
    path {
      stroke: var(--accordian-upload-loader) !important;
    }
  }
  &__table {
    border-top: 1px solid var(--accordian-contianer-border);
    margin-top: 30px;

    &__heading {
      font-size: 18px !important;
      color: var(--base-heading-color) !important;
      font-weight: 700 !important;
    }
    &__title {
      @include textEllipsis(140px);
      color: var(--base-heading-color);
      font-size: 14px !important;
      font-weight: 700 !important;
      cursor: pointer !important;
    }
    &__flag {
      img {
        border-radius: 50%;
        width: 26px !important;
        height: 26px !important;
        object-fit: cover;
      }
    }
    &__icons {
      &__upDown {
        // background-color: #ff3d81 !important;
        width: 30px;
        height: 30px;
        &:hover {
          background-color: none !important;
        }
        svg {
          fill: #222c41;
          font-weight: 700 !important;
        }
      }
    }
    &__img_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 25px;
      padding-bottom: 15px;
      &__img {
        display: flex;
        align-items: center;
        gap: 10px;
        &__youtube_line {
          width: 16px;
          height: 5px;
          background-color: #ff0000;
          border-radius: 12px;
        }
        &__spotify_line {
          width: 16px;
          height: 5px;
          background-color: #1ed760;
          border-radius: 12px;
        }
        &__tiktok_line {
          width: 16px;
          height: 5px;
          background-color: #00f7ef;
          border-radius: 12px;
        }
        &__apple_line {
          width: 16px;
          height: 5px;
          // background-color: #ffc007;
          background-color: #ffffff;
          border-radius: 12px;
        }
      }
    }
  }
  &__select {
    background-color: #ffff;
    outline: none;
    padding: 8px 25px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1250px) {
  .page {
    &__fileUpload {
      flex-wrap: wrap;
      div:nth-child(1) {
        width: 100%;
      }
      div:nth-child(2) {
        width: 100%;
        margin-top: 10px;
      }
      div:nth-child(3) {
        width: 100%;
        margin-top: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 920px) {
  .page {
    &__fileUpload {
      flex-wrap: wrap;
      div:nth-child(1) {
        width: 100%;
      }
      div:nth-child(2) {
        width: 100%;
        margin-top: 10px;
      }
      div:nth-child(3) {
        width: 100%;
        margin-top: 0px !important;
      }
    }
  }
}
