@import "../../../../styles/scss.scss";
@import "../../../../styles/colors.scss";

.page {
  font-family: "DM Sans";
  padding: 0px 20px;
  &__title {
    @include customTags(); //
  }
  &__button {
    @include activeThemeBotton();
    width: auto;
    padding: 10px 30px;
    margin-top: 20px !important;

    &:hover {
      background: var(--base-button-background-color) !important;
    }
  }

  &__recoupHeading {
    font-size: 24px !important;
    font-weight: bold !important;
    padding-bottom: 10px;
    color: var(--base-heading-color) !important;
  }
  // .radioBtn {
  //   color: var(--base-button-background-color) !important;
  // }
  &__mainInputField {
    position: relative;
    &__inputContainer {
      border-bottom: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      &:focus-within {
        border-bottom: 1px solid var(--base-input-focus-color);
      }
      &__inputFileds {
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--base-heading-color);
        @include customHeading(28px);
        width: 150px;
        text-align: center;
        font-family: "DM Sans";
      }
      &__action_btn {
        background-color: var(--base-action-icon-background) !important;
        color: var(--base-action-icon-color) !important;
        width: 25px;
        height: 25px;
        font-weight: bold;
        text-align: center;
        font-size: 25px !important;
      }
      &__dollar {
        position: absolute;
        left: 0px;
        bottom: 0px;
        @include customHeading(28px);
        font-family: "DM Sans";
      }
      &__dollarField {
        @include customHeading(28px);
        color: var(--base-heading-color) !important;
        padding-left: 20px;
      }
    }

    // .speedoMeter {
    //   background-color: transparent;
    //   border: none;
    //   outline: none;
    //   color: var(--base-heading-color);
    //   @include customHeading(28px);
    //   width: 150px;
    //   text-align: center;
    //   font-family: "DM Sans";
    // }
  }

  &__totalSpend {
    position: relative;
    &__walletImg {
      // margin-top: -20px;
      overflow: hidden;
      svg {
        stroke: var(--base-icon-color);
        fill: var(--base-container-backgroung-color);
        width: 100%;
        height: 350px;
      }
      &__walletContent {
        position: absolute;
        top: 100px;
        left: 30px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        &__text {
          @include customHeading(28px);
        }
      }
    }
  }
}
@media only screen and (max-width: 1549px) {
  .page {
    &__totalSpend {
      margin-top: 40px;
    }
  }
}
// @media only screen and (max-width: 1300px) {
//   .header {
//     .btnsPos {
//       right: 20px;
//       top: 1px;
//     }
//   }
// }

// @media only screen and (max-width: 580px) {
//   .page {
//     &__totalSpend {
//       &__walletImg {
//         img {
//           width: 100% !important;
//           height: 100% !important;
//         }
//         &__walletContent {
//           bottom: 5px;
//           left: 20px;
//           gap: 10px !important;
//         }
//       }
//     }
//   }
// }
