@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  height: 675px !important;
  max-height: 675px !important;
  background-color: var(--base-container-backgroung-color) !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  font-family: "DM Sans" !important;
  border: 1px solid #5a7380;
  border-left: none;
  overflow: hidden;

  &__topHead {
    // @include flexProperties(row, center, center, $fullWidth: false);
    height: 20.59% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 20.59% !important;
    border-bottom: 1px solid var(--base-border-line-color);
    &__img_logo {
      background-image: var(--side-drawer-top-icon);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      width: 155px;
      object-fit: cover;
    }
  }

  &__mainMenu {
    padding-top: 0px !important;
    color: #9196a0;
    height: 50.85% !important;
    max-height: 50.85% !important;

    &__list {
      padding: 10px 0px 5px 30px !important;

      &__logoutBtn {
        width: 100%;
        height: 40px;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        border-width: 2px;
        border-color: red;
        border-style: solid;
        border-radius: 15px;
        :hover {
          background-color: transparent !important;
        }
      }

      &:last-child {
        margin-bottom: 120px;
      }
      &:first-child {
        margin-top: 50px;
      }
    }

    &__button {
      padding: 0px !important;
      &__icon {
        margin-top: -8px;
        margin-right: -20px !important;
        fill: var(--base-icon-color);
      }
      &__text_border {
        color: var(--base-paragraph-color);
        border-right: 5px solid var(--base-tags-color) !important;
        opacity: 1 !important;
      }
      &__text {
        color: var(--base-paragraph-color);
        opacity: 0.5;
        border: none;
      }
    }
  }

  .inactive_icon {
    opacity: 0.5;
    margin-top: 5px;
  }
  .active_icon {
    margin-top: 5px;
  }

  // {/* Bottom - name and logout */}
  &__bottom_container {
    text-align: center;
    // background: var(--base-container-backgroung-color);
    padding: 20px 20px 0px 20px;
    // border-bottom-right-radius: 12px;
    border-top: 1px solid var(--base-border-line-color);
    // height: 28.26% !important;
    // max-height: 28.26% !important;

    img {
      fill: #e12020;
    }
    &__logOutbtn {
      margin-top: 15px;
      height: 40px !important;
      width: 100%;
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      background-color: transparent;
      border-radius: 12px;
      border: 2px solid #e12020;
      color: #e12020;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #e12020;
        color: white;
      }

      &__button_text {
        font-size: 14px;
        font-family: "DM Sans";
        font-weight: 700;
        padding-left: 5px;
      }
    }
  }

  .drawer_icon {
    height: 16px;
    width: 16px;
  }
  .drawer_LockIcon {
    font-size: 20px;
    color: var(--base-icon-color);
    margin-top: 10px;
    margin-left: -3px;
  }

  // &__icon_colors {
  //   height: 16px !important;
  //   width: 16px !important;
  //   color: var(--base-icon-color)s;
  // }
}

@media only screen and (max-width: 1100px) {
  .page {
    height: 100vh !important;
    max-height: 100vh !important;
  }
}
