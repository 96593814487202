@import "../../../../styles/colors.scss";
@import "../../../../styles/scss.scss";
.page {
  &__inputField {
    margin-left: 0px !important;
    font-size: 16px;
    width: 30px;
    // max-width: 140px;
    background-color: inherit;
    border-radius: 5px;
    border: 0;
    // padding: 4px 2px;
    color: #ffffff;
    // outline: 0;
    // text-align: right;
  }
  &__inputField::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
