@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);

  &__notes {
    flex: 1;
    width: 100%;
    overflow: hidden;
    // margin-left: 20px;

    &__box {
      &__tracks {
        width: 100%;
        background: #222c41 !important;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid #5a7380;

        &__adetails {
          &__header {
            @include flexProperties(
              row,
              space-between,
              center,
              $fullWidth: true
            );
            &__title {
              @include customHeading(24px);
            }
            &__editbtn {
              height: 30px;
              width: 30px;
              background-color: #ff3d81 !important;
              &__inner_btn {
                color: #222c41;
              }
            }
          }
        }
      }
    }

    &__notebox {
      width: 100%;
      background: #192233;
      border-radius: 12px;
      padding: 20px;

      &__chips {
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        gap: 10px !important;
        justify-content: space-between;
        @include customHeading(14px);
        &__chip_container {
          background-color: #ff3d81 !important;
        }
      }

      &__notetext {
        @include customParagraph(14px);
        text-align: justify;
        word-break: break-all;
      }
    }

    &__noteboxControls {
      @include flexProperties(column, center, center, $fullWidth: true);
      flex-direction: row !important;
      gap: 15px;

      :hover {
        cursor: pointer;
      }
    }

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      &__adetails {
        width: 100%;
        background: #222c41;
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;
          margin-bottom: 20px;

          &__title {
            color: #ffffff;
            font-size: 30px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: capitalize;
            font-family: "DM Sans";
          }

          &__Editbtn {
            background-color: #4ffcb7 !important;
            border-radius: 50% !important;
            width: 30px;
            height: 30px;
            padding: 0px;
            min-width: 30px !important;
          }
        }
      }

      &__tracks {
        width: 100%;
        background: #222c41;
        border-radius: 12px;
        padding: 20px;

        &__header {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          width: 100%;

          &__title {
            color: #4ffcb7;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          &__btn {
            background: #4ffcb7 !important;
            color: #4ffcb7 !important;
            border-radius: 25px !important;
            font-size: 14px;
            cursor: pointer;
            text-transform: uppercase;
            border: none;
            padding: 5px 20px !important;

            &:hover {
              background: #4ffcb7 !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__header {
            &__title {
              @include customHeading(20px);
            }

            &__search {
              width: 40%;

              &__seaarch_icon {
                left: 20px;
              }

              &__input {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
