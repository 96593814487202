@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, center, center, $fullWidth: true);
  min-height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "DM Sans";

  &__dialog {
    height: 100%;
    overflow: auto;
    width: 100%;
    background-color: #222c41;
    border-radius: 10px;
    box-shadow: 2px 2px 4px #000000;
    padding: 20px;
    color: #ffffff;
    border: 1px solid #5a7380;

    &__header {
      &__title {
        @include customHeading(20px);
      }
    }

    &__form {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      gap: 30px;
      margin-top: 30px;
      margin-bottom: 30px;

      &__field_container {
        @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
        gap: 24px;
        width: 100%;

        &__field {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          gap: 10px;

          &__label {
            @include customTags();
          }

          // &__input {
          //   color: #979797 !important;
          //   border: 2px solid #192233 !important;
          //   background: #192233;
          //   outline: none;
          //   padding: 0px 20px 0px 20px;
          //   font-size: 14px;
          //   border-radius: 8px;
          //   width: 100%;
          //   height: 40px !important;
          //   &:focus {
          //     border-color: #4ffcb7 !important;
          //     transition: all 0.3s ease-in-out !important;
          //   }
          //   option {
          //     background-color: #192233;
          //   }
          // }

          // &__input_date {
          //   width: 100%;
          //   color: #ffffff !important;
          //   border: 2px solid #4ffcb7 !important;
          //   background: #192233;
          //   outline: none;
          //   font-size: 18px;
          //   border-radius: 8px;
          //   // border-style: none !important;
          //   &:focus {
          //     border-color: #4ffcb7 !important;
          //     transition: all 0.3s ease-in-out !important;
          //   }
          //   &::placeholder {
          //     color: #fff;
          //   }
          // }
        }
      }

      &__actions {
        @include flexProperties(row, flex-end, center, $fullWidth: true);
        width: 100%;

        &__cancel_btn {
          @include cancelDeactiveBtn();
        }

        &__submit_btn {
          gap: 20px;
          @include activeThemeBotton();
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .page {
    &__dialog {
      &__form {
        overflow: auto;
        height: calc(100vh - 180px);

        &__field_container {
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          width: 100%;

          &__field {
            width: 100%;

            // &__input {
            //   padding: 10px;
            //   font-size: 14px;
            //   border-radius: 3px;
            // }

            // &__select {
            //   padding: 10px;
            //   font-size: 14px;
            //   border-radius: 3px;
            // }
          }
        }
      }
    }
  }
}
