@import "../../../../styles/scss.scss";
@import "../../../../styles/colors.scss";

.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 2px solid var(--base-img-border-color);
  height: auto;
  // overflow: hidden;
  position: sticky;
  top: 30px;
  z-index: 9;
  // box-shadow: var(box-shadow);
  box-shadow: "var(--deal-summary-box-shadow) !important";
  -webkit-box-shadow: "var(--deal-summary-webkit-box-shadow) !important";
  -moz-box-shadow: "var(--deal-summary-moz-box-shadow) !important";

  // box-shadow: 0px 0px 26px -2px rgba(255, 61, 129, 0.75);
  // -webkit-box-shadow: 0px 0px 26px -2px rgba(255, 61, 129, 0.75);
  // -moz-box-shadow: 0px 0px 26px -2px rgba(255, 61, 129, 0.75);
  &__container {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      // padding: 16px 20px;
      &__heading {
        color: var(--base-heading-color) !important;
        font-size: 1.2rem !important;
        font-weight: bold !important;
        text-transform: capitalize;
        white-space: nowrap;
      }
      &__btn {
        @include activeThemeBotton();
        padding: 1px 20px !important;
        width: auto !important;
      }
    }
    &__new_layout {
      border-top: 1px solid var(--base-border-line-color);
      &__shares {
        display: flex;
        div {
          flex-basis: 50%;
        }
        &__label {
          background-color: var(--deal-summary-active-background);
          padding: 10px 20px;
        }
        &__artist {
          padding: 10px 20px;
        }
        &__heading {
          color: var(--deal-summary-active-text-color) !important;
          font-size: 12px !important;
          text-transform: uppercase;
        }
        &__value {
          color: var(--deal-summary-active-text-color) !important;
          font-size: 20px !important;
          font-weight: bold !important;
        }
      }
      &__shares2 {
        display: flex;
        div {
          flex-basis: 50%;
        }
        &__label {
          background-color: var(--deal-summary-active-background);
          padding: 10px 20px;
        }
        &__artist {
          padding: 10px 20px;
        }
        &__heading {
          color: var(--deal-summary-deactive-text-color) !important;
          font-size: 12px !important;
          text-transform: uppercase;
        }
        &__value {
          // margin-top: -5px;
          color: var(--deal-summary-deactive-text-color) !important;
          font-size: 20px !important;
          font-weight: bold !important;
        }
      }
      &__masters {
        background-color: #1985ff;
        &__heading {
          font-weight: 400 !important;
          letter-spacing: 3px !important;
          font-size: 18px !important;
          padding: 0px 20px;
          text-transform: uppercase;
          color: var(--base-heading-color) !important;
        }
      }
      &__details {
        // border-bottom: 1px solid var(--base-border-line-color) !important;
        padding: 10px 0px;
        &__divider {
          height: 1px;
          background-color: var(--base-border-line-color) !important;
        }
        &__container {
          padding: 0px 20px;
          &__main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            &__heading {
              color: #fff !important;
              font-size: 12px !important;
              text-transform: uppercase;
              white-space: nowrap;
            }
          }
        }
        &__new_music {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 20px 10px 20px;
          div {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 0px 20px 0px 0px;
          }
          div:nth-child(1) {
            border-right: 1px solid var(--base-border-line-color);
          }
          div:nth-child(2) {
            border-right: 1px solid var(--base-border-line-color);
          }
          div:nth-child(3) {
            padding: 0px;
          }
        }
      }
    }
  }
  &__loader {
    width: 27px !important;
    height: 27px !important;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
  &__drop {
    position: relative;
    &__shape {
      position: absolute;
      width: 222px;
      height: 243.33px;
      padding: 10px 10px 10px 20px;
      border: 2px solid var(--base-border-line-color);
      // background: var(--base-inner-container-background-color);
      background: #214646;
      border-radius: 12px 0px 12px 12px;
      margin-top: 20px;
      // margin-right: 10px;
      right: 12px;
      &::before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        // border-bottom: 15px solid var(--base-inner-container-background-color) !important;
        border-bottom: 15px solid #214646 !important;
        border-left: 15px solid transparent !important;
        right: 0px;
        top: -15px;
        z-index: 22;
      }
      &::after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-bottom: 20px solid var(--base-border-line-color);
        border-left: 20px solid transparent;
        right: -2px;
        top: 0px;
        margin-top: -20px;
      }
    }
    &__worldList {
      &__heading {
        @include customTags();
        font-size: 11px !important;
      }
      &__list {
        // gap: 20px;
        height: 180px;
        // margin-top: 5px;
        overflow-y: scroll;
        // -ms-overflow-style: none !important; /* IE and Edge */
        // scrollbar-width: none !important;
        &__items {
          margin-top: 5px;
          // margin-right: 20px;
          padding-right: 10px;
          // cursor: pointer;
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: space-between;
          &__flag {
            font-size: 30px;
            // border-radius: 50% !important;
            // width: 40px !important;
            // height: 30px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            &__text {
              color: #fff !important;
              font-size: 16px !important;
            }
            // overflow: hidden;
          }
          &__text {
            // margin-top: -4px;
            font-size: 16px !important;
            color: white !important;
            font-weight: 500 !important;
            white-space: nowrap !important;
          }
        }
      }
    }
  }
  &__blur {
    width: 70px !important;
    height: 15px !important;
    border-radius: 8px !important;
    background-color: #6dc9a4 !important;
    margin: 5px 0px !important;
    cursor: pointer !important;
    filter: blur(1px) !important;
  }
}
@media only screen and (max-width: 1535px) {
  .page {
    &__container {
      &__top {
        padding: 16px 20px !important;
      }
      &__new_layout {
        &__shares {
          &__heading {
            font-size: 9px !important;
          }
          &__value {
            font-size: 15px !important;
          }
        }
        &__shares2 {
          &__heading {
            font-size: 9px !important;
          }
          &__value {
            font-size: 15px !important;
          }
        }
        &__masters {
          &__heading {
            font-size: 15px !important;
          }
        }
        &__details {
          &__container {
            &__main {
              &__heading {
                font-size: 10px !important;
              }
              &__value {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
    &__loader {
      width: 21px !important;
      height: 21px !important;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .page {
    // top: 55px !important;
    margin-top: 5px;
    &__container {
      &__new_layout {
        &__shares {
          &__heading {
            font-size: 9px !important;
          }
          &__value {
            font-size: 15px !important;
          }
        }
        &__masters {
          &__heading {
            font-size: 15px !important;
          }
        }
        &__details {
          &__container {
            &__main {
              &__heading {
                font-size: 9px !important;
              }
              &__value {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .page {
    margin-top: 10px !important;

    &__container {
      &__top {
        // padding: 20px !important;

        &__heading {
          font-size: 18px !important;
        }
      }
      &__new_layout {
        &__shares {
          &__heading {
            font-size: 9px !important;
          }
          &__value {
            font-size: 15px !important;
          }
        }
        &__masters {
          &__heading {
            font-size: 15px !important;
          }
        }
        &__details {
          &__container {
            &__main {
              &__heading {
                font-size: 9px !important;
              }
              &__value {
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }
    &__loader {
      width: 19.5px !important;
      height: 19.5px !important;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .page {
    margin-top: 10px !important;

    &__container {
      &__top {
        &__heading {
          font-size: 16px !important;
        }
      }
      &__new_layout {
        &__shares {
          &__heading {
            font-size: 9px !important;
          }
          &__value {
            font-size: 15px !important;
          }
        }
        &__masters {
          &__heading {
            font-size: 15px !important;
          }
        }
        &__details {
          &__container {
            &__main {
              &__heading {
                font-size: 9px !important;
              }
              &__value {
                font-size: 13px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 899px) {
  .page {
    margin-top: 10px !important;

    &__container {
      &__new_layout {
        &__shares {
          &__heading {
            font-size: 10px !important;
          }
          &__value {
            font-size: 15px !important;
          }
        }
        &__masters {
          &__heading {
            font-size: 15px !important;
          }
        }
        &__details {
          &__container {
            &__main {
              &__heading {
                font-size: 12px !important;
              }
              &__value {
                font-size: 15px !important;
              }
            }
          }
        }
      }
    }
    &__loader {
      width: 25px !important;
      height: 25px !important;
    }
  }
}
