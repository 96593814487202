.page {
  &__select {
    background-color: #ffff;
    outline: none;
    padding: 8px 25px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    border: none;
    cursor: pointer;
  }
}
