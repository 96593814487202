@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 183px;
  // overflow: hidden;
  &__container {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px 0px 20px 20px;
    &__img {
      margin-top: -10px;
      width: 144px !important;
      height: 142px !important;
      border: 4px solid var(--base-img-border-color) !important;
      border-radius: 12px !important;
      object-fit: cover 1;
    }
    &__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-wrap: wrap;
        height: 35px;
        padding-right: 20px;
        // width: 100%;
        &__name {
          &__artist_name {
            color: var(--base-paragraph-color) !important;
            font-size: 24px !important;
            font-weight: bold !important;
            cursor: pointer;
            transition: all 0.3s ease-in-out !important;
            &:hover {
              color: var(--base-tags-color) !important;
            }
          }
        }
        &__icon {
          background-color: var(--base-action-icon-background) !important;
          width: 22px !important;
          height: 22px !important;
          text-align: center !important;
          margin-right: 20px !important;
          padding: 0px 10px 6px 10px !important;
          svg {
            width: 10px;
            height: 10px;
            fill: var(--base-action-icon-color);
          }
        }
        &__update_btn {
          @include activeThemeBotton();
          padding: 7px 20px !important;
          &__rotate {
            animation: rotate 1s linear infinite;
            @keyframes rotate {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
      &__bottom {
        display: flex;
        width: 100%;
        margin-top: 15px;
        height: 111px;
        border-top: 1px solid var(--base-border-line-color);
        div:last-child {
          border: none !important;
        }
        &__box {
          flex-basis: 20% !important;
          border-right: 1px solid var(--base-border-line-color);
          &__detail {
            display: flex;
            flex-direction: column;
            padding: 20px 10px 20px 0px;
            &__heading {
              @include customTags();
            }
            &__value {
              @include customValue(21px);
              padding-top: 5px;
            }
            &__subPara {
              color: var(--base-paragraph-color) !important;
              font-size: 10px !important;
              white-space: nowrap;
              text-align: center;
            }
          }
        }
      }
    }
  }
  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}
@media only screen and (max-width: 1550px) {
  .page {
    height: 100% !important;
    &__container {
      gap: 10px;
      padding: 10px 0px 0px 10px !important;
      &__img {
        width: 80px !important;
        height: 80px !important;
      }
      &__details {
        padding: 0px;
        &__top {
          &__name {
            &__artist_name {
              font-size: 20px !important;
            }
          }

          &__update_btn {
            padding: 5px 10px !important;
            font-size: 10px !important;
          }
        }
        &__bottom {
          height: 100%;

          &__box {
            // flex-basis: 50% !important;
            &__detail {
              padding: 10px 5px 5px 10px !important;
              &__heading {
                font-size: 12px !important;
              }
              &__value {
                @include customValue(20px);
              }
              &__subPara {
                font-size: 8px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .page {
    &__container {
      &__details {
        &__top {
          // &__update_btn {
          //   padding: 5px 10px !important;
          //   font-size: 10px !important;
          // }
        }
        &__bottom {
          &__box {
            &__detail {
              &__value {
                @include customValue(20px);
              }
              &__subPara {
                font-size: 6px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .page {
    &__container {
      &__details {
        &__bottom {
          &__box {
            &__detail {
              &__heading {
                font-size: 12px !important;
              }
              &__value {
                @include customValue(18px);
              }
              &__subPara {
                font-size: 6px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .page {
    &__container {
      &__details {
        &__bottom {
          &__box {
            &__detail {
              &__heading {
                font-size: 12px !important;
              }
              &__value {
                @include customValue(18px);
              }
              &__subPara {
                font-size: 8px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 899px) {
  .page {
    &__container {
      &__details {
        &__bottom {
          &__box {
            &__detail {
              &__heading {
                font-size: 12px !important;
              }
              &__value {
                @include customValue(15px);
              }
              &__subPara {
                font-size: 8px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .page {
    &__container {
      &__details {
        &__bottom {
          &__box {
            &__detail {
              &__heading {
                font-size: 12px !important;
              }
              &__value {
                @include customValue(15px);
              }
              &__subPara {
                font-size: 8px !important;
              }
            }
          }
        }
      }
    }
  }
}

// ? Old css dont remove

// @import "../../../styles/scss.scss";
// @import "../../../styles/colors.scss";

// .page {
//   background-color: var(--base-container-backgroung-color);
//   border-radius: 12px;
//   border: 1px solid var(--base-border-line-color);
//   width: 100%;
//   height: 183px;
//   // overflow: hidden;

//   &__container {
//     display: flex;
//     gap: 20px;
//     align-items: center;
//     padding: 20px 0px 20px 20px;
//     &__img {
//       margin-top: -10px;
//       width: 144px !important;
//       height: 142px !important;
//       border: 4px solid var(--base-img-border-color) !important;
//       border-radius: 12px !important;
//       object-fit: cover 1;
//     }
//     &__details {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       width: 100%;
//       &__top {
//         display: flex;
//         justify-content: space-between;
//         flex-wrap: wrap;
//         height: 35px;
//         // width: 100%;
//         &__name {
//           &__artist_name {
//             color: var(--base-paragraph-color) !important;
//             font-size: 24px !important;
//             font-weight: bold !important;
//             cursor: pointer;
//             transition: all 0.3s ease-in-out !important;
//             &:hover {
//               color: var(--base-tags-color) !important;
//             }
//           }
//         }
//         &__icon {
//           background-color: var(--base-action-icon-background) !important;
//           width: 22px !important;
//           height: 22px !important;
//           text-align: center !important;
//           margin-right: 20px !important;
//           padding: 0px 10px 6px 10px !important;
//           svg {
//             width: 10px;
//             height: 10px;
//             fill: var(--base-action-icon-color);
//           }
//         }
//       }
//       &__bottom {
//         display: flex;
//         width: 100%;
//         margin-top: 15px;
//         height: 111px;
//         border-top: 1px solid var(--base-border-line-color);
//         &__box {
//           flex-basis: 23% !important;
//           // flex-grow: 1;
//           border-right: 1px solid var(--base-border-line-color);
//           &__detail {
//             display: flex;
//             flex-direction: column;
//             // gap: 10px;
//             padding: 20px 10px 20px 0px;
//             &__heading {
//               @include customTags();
//             }
//             &__value {
//               @include customValue();
//               padding-top: 5px;
//             }
//             &__subPara {
//               color: var(--base-paragraph-color) !important;
//               font-size: 10px !important;
//               white-space: nowrap;
//             }
//           }
//         }
//       }
//     }
//   }
//   &__loader {
//     width: 100%;
//     path {
//       stroke: var(--base-tags-color) !important;
//     }
//   }
// }

// @media only screen and (max-width: 1100px) {
//   .page {
//     height: 100% !important;
//     &__container {
//       padding: 0px !important;
//       display: flex;
//       flex-direction: column !important;
//       &__img {
//         margin-top: -40px;
//       }
//       &__details {
//         padding: 0px;
//         align-items: center;
//         &__top {
//           &__name {
//             text-align: center;
//             &__artist_name {
//               text-align: center !important;
//             }
//           }
//           &__icon {
//             display: none !important;
//           }
//         }
//         &__bottom {
//           &__box {
//             padding-left: 0px !important;
//             padding-right: 10px;
//             &__detail {
//               padding-left: 10px !important;
//               &__value {
//                 // font-size: 24px !important;
//               }
//               &__subPara {
//                 font-size: 8px !important;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 800px) {
//   .page {
//     // overflow: hidden;
//     height: 570px !important;
//     &__container {
//       &__details {
//         &__bottom {
//           flex-direction: column;
//           &__box {
//             border-top: 1px solid var(--base-border-line-color);
//             border-right: 0px !important ;
//             &__detail {
//               padding-left: 10px !important;
//               &__value {
//                 @include customValue(22px);
//               }
//               &__subPara {
//                 font-size: 10px !important;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
