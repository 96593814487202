@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222c41;
    font-size: 20px;
    font-weight: bold;
    background-color: #4ffcb7;
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: #222c41 !important;
        color: #4ffcb7 !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: #4ffcb7;
        }
      }
    }
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 50px !important;
  }
}

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  &__details {
    flex: 1;
    width: 100%;
    &__box {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 100%;
      height: 100% !important;
      &__tracks {
        width: 100%;
        // background: var(--base-container-backgroung-color);
        // border-radius: 12px;
        // border: 1px solid var(--base-border-line-color);

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // @include flexProperties(row, flex-start, center, $fullWidth: true);
          width: 100%;
          gap: 10px !important;
          background: var(--base-container-backgroung-color);
          border-radius: 12px;
          border: 1px solid #5a7380;
          padding: 20px;
          &__back_icon {
            font-size: 25px;
            color: var(--base-icon-color);
            margin-top: 10px;
            cursor: pointer;
          }
          &__avatar {
            width: 56px !important;
            height: 56px !important;
            border: 1px solid #4ffcb7 !important;
            border-radius: 6px !important;
          }
          &__title {
            @include customHeading(20px);
            font-weight: 500 !important;
            padding-bottom: 5px;
          }
          &__chip {
            background-color: #5f737f !important;
            color: #fff !important;
          }
          &__text {
            font-size: 12px !important;
            font-weight: 400 !important;
            color: #4ffcb7 !important;
            text-transform: uppercase !important;
            padding-bottom: 5px !important;
          }
          &__date {
            font-size: 12px !important;
            font-weight: 700 !important;
            color: #fff !important;
          }
        }
        &__details {
          background: var(--base-container-backgroung-color);
          border-radius: 12px;
          border: 1px solid var(--base-border-line-color);
          margin-top: 20px;
          &__detail1 {
            @include flexProperties(
              row,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 111px !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__container {
              @include flexProperties(
                column,
                center,
                flex-start,
                $fullWidth: true
              );
              // width: 18.39%;
              width: 100%;
              height: 100%;
              padding: 20px;
              border-right: 1px solid #5a7380 !important;
              overflow: hidden !important;
              &__text {
                @include customTags();
                font-size: 14px !important;
                text-transform: uppercase !important;
              }
              &__value {
                @include customHeading(24px);
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                gap: 5px;
              }
              &__count {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #4ffcb7;
                color: #192233;
                font-size: 10px;
                display: flex;

                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        &__stream_container {
          background: var(--base-container-backgroung-color);
          border-radius: 12px;
          border: 1px solid var(--base-border-line-color);
          margin-top: 20px;
          @include flexProperties(
            column,
            flex-start,
            flex-start,
            $fullWidth: true
          );
          height: 100% !important;
          padding: 20px;

          &__header {
            @include flexProperties(
              row,
              space-between,
              center,
              $fullWidth: true
            );
            width: 100%;
            &__heading {
              @include customHeading(24px);
            }
            &__streams {
              color: #192233 !important;
              background-color: #4ffcb7;
              border-radius: 25px !important;
              padding: 5px 20px;
              text-transform: uppercase !important;
              font-size: 12px !important;
              font-weight: 600 !important;
            }
          }

          &__box_container {
            @include flexProperties(
              column,
              space-between,
              flex-start,
              $fullWidth: true
            );
            width: 100% !important;
            margin-top: 30px;
            gap: 20px !important;
            &__box {
              width: 100% !important;
              border-radius: 20px;
              background-color: var(--base-inner-container-background-color);

              &__header {
                @include flexProperties(
                  row,
                  space-between,
                  center,
                  $fullWidth: true
                );
                padding: 20px 20px 15px 20px;
                &__icon_title_container {
                  @include flexProperties(
                    row,
                    flex-start,
                    center,
                    $fullWidth: true
                  );

                  &__icon {
                    height: 24px;
                    width: 24px;
                  }
                  &__title {
                    margin-left: 10px;
                    @include customParagraph(16px);
                    font-weight: 500 !important;
                  }
                }
                &__icon_container {
                  // height: 30px !important;
                  // width: 30px !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  &__menu_icon {
                    // height: 2px;
                    // width: 10px;
                    font-size: 25px;
                    cursor: pointer;
                    color: var(--base-icon-color);
                  }
                }
              }

              &__streams_container {
                @include flexProperties(row, center, center, $fullWidth: true);
                width: 100%;
                gap: 10px;
                // padding: 0px 20px 0px 20px;
                &__title {
                  @include customHeading(24px);
                  // width: 20% !important;
                  margin-right: 20px;
                }
              }

              &__buttom_container {
                width: 100%;
                padding: 0px 20px 0px 20px;
                &__heading {
                  @include customTags();
                }
              }

              &__country_data {
                border-top: 1px solid #5a7380;
                margin-top: 25px;
                overflow-y: scroll;
                height: 400px !important;
                &::-webkit-scrollbar {
                  display: none;
                }
                &__icon_streams {
                  @include flexProperties(
                    column,
                    flex-start,
                    flex-start,
                    $fullWidth: true
                  );
                  width: 100%;
                  padding: 20px;
                  &__inner {
                    @include flexProperties(
                      row,
                      center,
                      center,
                      $fullWidth: true
                    );
                    width: 100%;
                    &__icon {
                      @include customParagraph(16px);
                      margin-right: 10px !important;
                    }
                    &__streams {
                      @include customParagraph(16px);
                      font-weight: 500;
                    }
                  }
                }

                &__graph {
                  @include flexProperties(
                    column,
                    center,
                    center,
                    $fullWidth: true
                  );
                  width: 100% !important;
                }
              }
            }

            // &__spotify {
            // }
          }
        }
      }
    }
  }
  &__loader_container {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100% !important;
    &__loader {
      width: 100%;
      path {
        stroke: var(--base-tags-color) !important;
      }
    }
  }
}

/* Mobile */
@media screen and (min-width: 361px) and (max-width: 375px) {
  /* Your CSS styles for devices with a width between 361px and 375px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__lineOne {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            // border-bottom: 1px solid #5a7380 !important;
            &__genre {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__release_date {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__year_ext {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__lineTwo {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: true
            );
            height: 100% !important;
            width: 100% !important;
            border-bottom: 1px solid #5a7380 !important;
            &__own_perc {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__producer {
              border-bottom: 1px solid #5a7380 !important;
              border-right: 0px solid #5a7380 !important;
            }

            &__isrc_code {
              border-right: 0px solid #5a7380 !important;
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  /* Your CSS styles for devices with a width between 376px and 414px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                // margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 415px) and (max-width: 599px) {
  /* Your CSS styles for devices with a width between 376px and 414px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );

              &__box {
                width: 100%;
                // margin-bottom: 20px;
              }

              // &__spotify {
              // }
            }
          }
        }
      }
    }
  }
}

/* Tablet */
@media screen and (min-width: 600px) and (max-width: 767px) {
  /* Your CSS styles for tablets with a width between 600px and 767px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__header {
            &__title {
              font-size: 15px !important;
            }
          }
          &__details {
            &__detail1 {
              &__container {
                &__text {
                  font-size: 12px !important;
                }
                &__value {
                  @include customHeading(20px);
                }
              }
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );
              &__box {
                width: 100%;
                // margin: 0px 0px 10px 0px;
              }

              // &__spotify {
              // }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Your CSS styles for tablets with a width between 768px and 1024px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;

          &__details {
            &__detail1 {
              &__container {
                &__text {
                  font-size: 12px !important;
                }
                &__value {
                  @include customHeading(22px);
                }
              }
            }
          }

          &__stream_container {
            &__box_container {
              @include flexProperties(
                column,
                space-between,
                flex-start,
                $fullWidth: true
              );
              width: 100%;
              &__box {
                width: 100%;
                // margin-bottom: 20px;
              }

              // &__spotify {
              // }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1279px) {
  /* Your CSS styles for tablets with a width between 800px and 1280px */
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  /* Your CSS styles for tablets with a width between 1024px and 1366px */
  .page {
    &__details {
      &__box {
        &__tracks {
          height: 100% !important;
          &__stream_container {
            &__box_container {
              &__box {
                width: max-content;
              }
            }
          }
        }
      }
    }
  }
}

/* Laptop */
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  /* Your CSS styles for laptops with a width between 1280px and 1366px */
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  /* Your CSS styles for laptops with a width between 1367px and 1920px */
  .page {
    &__details {
      &__box {
        &__tracks {
          &__stream_container {
            &__box_container {
              &__box {
                width: 100%;
                margin-right: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

/* Desktop */
@media screen and (min-width: 1921px) and (max-width: 2559px) {
  /* Your CSS styles for desktops with a width between 1921px and 2560px */
  .page {
    &__details {
      &__box {
        &__tracks {
          &__stream_container {
            &__box_container {
              &__box {
                width: 100%;
                margin: 0px 20px 0px 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2560px) and (max-width: 3840px) {
  /* Your CSS styles for desktops with a width between 2561px and 3840px */
  .page {
    &__details {
      &__box {
        &__tracks {
          &__stream_container {
            &__box_container {
              &__box {
                width: 100%;
                margin-right: 20px;

                &__streams_container {
                  &__title {
                    margin-top: -10px;
                    @include customHeading(30px);
                  }
                }

                &__buttom_container {
                  &__heading {
                    @include customTags();
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
