@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  background: #222c41;
  border-radius: 12px;
  border: 1px solid #5a7380;
  min-height: 834px !important;
  max-height: 834px !important;
  width: 100% !important;
  padding: 20px !important;
  overflow: hidden;
}
