// Create Music Group Color Pllette
// :root {
//   // ? for background container
//   --base-background-color: hsla(0, 0, 0, 0);
//   --base-backgroundImage-url: url("../assets/login_bg.png");
//   --base-login-background-img: url("../assets/login_bg.png");
//   --base-container-backgroung-color: #222c41;
//   --base-inner-container-background-color: #192233;
//   --base-hover-list-color: rgb(43, 43, 43);
//   // --base-search-artist-gredient: linear-gradient(
//   //   to right,
//   //   #ff3d81,
//   //   #ff3d81,
//   //   #ff9c9c,
//   //   #e4f1ee,
//   //   #e4f1ee
//   // );
//   --base-search-add-artist-bg-color: hsla(338, 100%, 62%, 1);
//   --base-search-add-artist-bg-color-shade: radial-gradient(
//       at 0% 100%,
//       hsla(220, 56%, 25%, 1) 0px,
//       transparent 50%
//     ),
//     radial-gradient(at 100% 100%, hsla(220, 56%, 25%, 1) 0px, transparent 50%);
//   --base-let-start-container-gradient: linear-gradient(
//     to right,
//     #ff3d81,
//     #ff3d81,
//     #0091b5,
//     #0091b5,
//     #0091b5
//   );

//   // ?  Sidee Drawer
//   --side-drawer-top-icon: url("../assets/cmg-logo.png");

//   // ? Picture border
//   --picture-border-color: #ff3d81;

//   // ? Loader color
//   --base-loader-color: #ff3d81;

//   // ? CheckBox
//   --checkbox-check-color: #ff3d81;
//   --checkbox-uncheck-color: #ff3d81;

//   // ? Tabs
//   --base-tabs-active-background: #ff3d81;
//   --base-tabs-active-text: #222c41;

//   // ? Select
//   --base-select-background-color: #ff3d81;
//   --base-text-color: #222c41;

//   // ? For text
//   --base-tags-color: #ff3d81;
//   --base-heading-color: #ffffff;
//   --base-paragraph-color: #ffffff;
//   --base-hover-color: #ff3d81;

//   //  ? for button active & deactive
//   --base-button-background-color: #ff3d81;
//   --base-button-text-color: #222c41;
//   --base-deactive-button-background-color: #5a7380;
//   --base-button-deactive-text-color: #222c41;
//   --base-button-inner-icon-color: #222c41;

//   // ? for input
//   --base-input-background-color: #192233;
//   --base-input-text-color: #979797;
//   --base-input-focus-color: #ff3d81;
//   --base-input-border-color: #5a7380;
//   --base-input-icon-color: #979797;
//   --base-hover-background-color: #ff3d81;
//   --base-hover-text-color: #192233;

//   // ? search input
//   --base-search-container-background: #202632;
//   --base-search-container-icon-color: #ff3d81;
//   --base-search-input-text-color: #ffffff;

//   // ? border line
//   --base-border-line-color: #5a7380;
//   --base-img-border-color: #ff3d81;

//   //  ? Graph lines color
//   --base-graph-line-color: #ff3d81;

//   // ? for svg color
//   --base-icon-color: #ff3d81;
//   --base-action-icon-color: #222c41;
//   --base-action-icon-background: #ff3d81;
//   --base-action-icon-delete-background: #f95f5f;
//   --base-action-disable-icon-color: #222c41;
//   --base-action--disable-icon-background: #5a7380;

//   // ? for Select icon svg
//   --base-select-svg-color: #222c41;

//   // ? Selection Tabs
//   --base-tab-active-background: #ff3d81;
//   --base-tab-deactive-background: #ff3d81;

//   // ? Toast color
//   --base-error-toast-color: #ff9c9c;
//   --base-warning-toast-color: #ff9c9c;
//   --base-success-toast-color: #ff99bd;
//   --base-close-icon-toast-color: #ff0000;
//   --base-toast-font-color: #222c41;

//   // ? Modal
//   --base-title-bg-color: #ff3d81;
//   --base-title-font-color: #222c41;

//   // ? for PreLoader background
//   --base-main-bg-color: hsla(338, 100%, 62%, 1);
//   --base-main-bg-shades: radial-gradient(
//       at 0% 7%,
//       hsla(220, 31%, 19%, 1) 0px,
//       transparent 50%
//     ),
//     radial-gradient(at 93% 96%, hsla(220, 31%, 19%, 1) 0px, transparent 50%),
//     radial-gradient(at 15% 93%, hsla(220, 31%, 19%, 1) 0px, transparent 50%),
//     radial-gradient(at 96% 6%, hsla(220, 31%, 19%, 1) 0px, transparent 50%);
//   --modal-background-color: #97284f;
//   --chart-lines-colors: #ff3d81;

//   // Deal Summary Box Shadow
//   --deal-summary-box-shadow: 0px 0px 26px -2px rgba(255, 61, 129, 0.75);
//   --deal-summary-webkit-box-shadow: 0px 0px 26px -2px rgba(255, 61, 129, 0.75);
//   --deal-summary-moz-box-shadow: 0px 0px 26px -2px rgba(255, 61, 129, 0.75);

//   // Scroll Bars Colors

//   --scroll-bar-background-color: #ff3d8150;
//   --scroll-bar-thumb-color: #ff3d81;

//   // Second Action Buttons Colors and Border and Text
//   --second-button-border-color: #ff3d81;
//   --second-button-color: #ffffff;
//   --second-button-background-color: #97284f;

//   // Deal Summary Active and Deaction Portions Design
//   --deal-summary-active-background: #97284f;
//   --deal-summary-active-text-color: #ffffff;
//   --deal-summary-deactive-background: #222c41;
//   --deal-summary-deactive-text-color: #ffffff;

//   // Table album Details Modal
//   --pop-up-background-color: #97284f;
//   --pop-up-text-color: #ffffff;

//   // accordian in uplaod file
//   --accordian-contianer-background: #192233;
//   --accordian-contianer-text: #ffffff;
//   --accordian-contianer-border: #ff3d81;
//   --accordian-upload-loader: #222c41;
//   --accordian-table-bottom-border: #454c5a;
//   --accordian-table-expand-row: #111724;

//   // --index background-color
//   --base-responsive-background-color-error: rgb(255, 152, 190);
// }

// Blacklion Color Pllette
:root {
  //Sidee Drawer
  --side-drawer-top-icon: url("../assets/LoginTopLogo.png");

  //Picture border
  --picture-border-color: #4ffcb7;

  // CheckBox
  --checkbox-check-color: #4ffcb7;
  --checkbox-uncheck-color: #ffffff;

  // Tabs
  --base-tabs-active-background: #4ffcb7;
  --base-tabs-active-text: #222c41;

  // ? Select
  --base-select-background-color: #4ffcb7;
  --base-text-color: #222c41;

  // ? For text
  --base-tags-color: #4ffcb7;
  --base-heading-color: #ffffff;
  --base-paragraph-color: #ffffff;
  --base-hover-color: #4ffcb7;

  //  ? for button active & deactive
  --base-button-background-color: #4ffcb7;
  --base-button-text-color: #222c41;
  --base-deactive-button-background-color: #5a7380;
  --base-button-deactive-text-color: #ffffff;
  --base-button-inner-icon-color: #4ffcb7;

  // ? for background container
  --Pre-Loader-Img: url("../assets/cmg-logo.png");
  --base-content-app-bg: radial-gradient(
    circle,
    rgba(79, 252, 183, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  --base-background-color: hsla(156, 96%, 64%, 0.72);
  --base-login-background-img: url("../assets/login_bg_BLA.png");
  --base-hover-list-color: rgb(43, 43, 43);
  --base-backgroundImage-url: radial-gradient(
      at 80% 50%,
      hsla(216, 27%, 15%, 0.58) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, hsla(216, 27%, 15%, 0.8) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(216, 27%, 15%, 0.49) 0px, transparent 50%),
    radial-gradient(at 22% 22%, hsla(216, 27%, 15%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(216, 27%, 15%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(216, 27%, 15%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(216, 27%, 15%, 1) 0px, transparent 50%);
  --base-container-backgroung-color: #222c41;
  --base-inner-container-background-color: #192233;
  --base-let-start-container-gradient: linear-gradient(
    to right,
    #175668,
    #175668,
    #19c39e,
    #19c39e,
    #19c39e
  );

  // --base-search-artist-gredient: linear-gradient(
  //   to right,
  //   #175668,
  //   #175668,
  //   #19c39e,
  //   #e4f1ee,
  //   #e4f1ee
  // );
  --base-search-add-artist-bg-color: hsla(156, 96%, 64%, 0.72);
  --base-search-add-artist-bg-color-shade: radial-gradient(
      at 0% 100%,
      hsla(220, 56%, 25%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 100% 100%, hsla(220, 56%, 25%, 1) 0px, transparent 50%);

  // ? for input
  --base-input-background-color: #192233;
  --base-input-text-color: #ffffff;
  --base-input-plpaceholder-text-color: #979797;
  --base-input-focus-color: #4ffcb7;
  --base-input-border-color: #5a7380;
  --base-input-icon-color: #979797;
  --base-hover-background-color: #4ffcb7;
  --base-hover-text-color: #192233;

  // ? search input
  --base-search-container-background: #202632;
  --base-search-container-icon-color: #4ffcb7;
  --base-search-input-text-color: #ffffff;

  // ? border line
  --base-border-line-color: #5a7380;
  --base-img-border-color: #4ffcb7;

  //  ? Graph lines color
  --base-graph-line-color: #4ffcb7;

  // ? for svg color
  --base-select-svg-color: #4ffcb7;
  --base-icon-color: #4ffcb7;
  --base-action-icon-color: #222c41;
  --base-action-icon-background: #4ffcb7;
  --base-action-icon-delete-background: #f95f5f;

  --base-action-disable-icon-color: #222c41;
  --base-action--disable-icon-background: #d9d9d9;

  // ? for Select icon svg
  // --base-select-svg-color: #222c41;

  // ? Selection Tabs
  --base-tab-active-background: #4ffcb7;
  --base-tab-deactive-background: #4ffcb7;

  // ? Toast color
  --base-error-toast-color: #fae1e2;
  --base-warning-toast-color: #fadfc5;
  --base-success-toast-color: #4ffcb7;
  --base-close-icon-toast-color: #ff0000;
  --base-toast-font-color: #222c41;

  // ? Modal
  --base-title-bg-color: #4ffcb7;
  --base-title-font-color: #222c41;

  // ? for PreLoader background
  --base-main-bg-color: hsla(156, 96%, 64%, 0.72);
  --base-main-bg-shades: radial-gradient(
      at 80% 50%,
      hsla(216, 27%, 15%, 0.58) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, hsla(216, 27%, 15%, 0.8) 0px, transparent 50%),
    radial-gradient(at 0% 50%, hsla(216, 27%, 15%, 0.49) 0px, transparent 50%),
    radial-gradient(at 22% 22%, hsla(216, 27%, 15%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 100%, hsla(216, 27%, 15%, 1) 0px, transparent 50%),
    radial-gradient(at 80% 100%, hsla(216, 27%, 15%, 1) 0px, transparent 50%),
    radial-gradient(at 0% 0%, hsla(216, 27%, 15%, 1) 0px, transparent 50%);

  --modal-background-color: #4ffcb790;
  --chart-lines-colors: #4ffcb7;

  --scroll-bar-background-color: #4ffcb750;
  --scroll-bar-thumb-color: #4ffcb7;

  // Second Action Buttons Colors and Border and Text
  --second-button-border-color: #4ffcb7;
  --second-button-color: #222c41;
  --second-button-background-color: #4ffcb7;

  // Deal Summary Active and Deaction Portions Design
  --deal-summary-active-background: #16a26a;
  --deal-summary-active-text-color: #ffffff;
  --deal-summary-deactive-background: #222c41;
  --deal-summary-deactive-text-color: #ffffff;

  // Table album Details Modal
  --pop-up-background-color: #16a26a;
  --pop-up-text-color: #222c41;

  // accordian in uplaod file
  --accordian-contianer-background: #192233;
  --accordian-contianer-text: #ffffff;
  --accordian-contianer-border: #4ffcb7;
  --accordian-upload-loader: #222c41;
  --accordian-table-bottom-border: #454c5a;
  --accordian-table-expand-row: #111724;

  // --index background-color
  --base-responsive-background-color-error: hsla(156, 96%, 64%, 0.72);
}
