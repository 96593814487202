@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222c41;
    font-size: 20px;
    font-weight: bold;
    background-color: #4ffcb7;
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: #222c41 !important;
        color: #4ffcb7 !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: #4ffcb7;
        }
      }
    }
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 50px !important;
  }
}

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 100%;
      &__tracks {
        width: 100%;
        background: var(--base-container-backgroung-color);
        border-radius: 12px;
        height: 675px !important;
        border-radius: 12px;
        overflow: hidden;
        padding: 10px 20px;
        border: 1px solid var(--base-border-line-color);
        &__loader_container {
          @include flexProperties(column, center, center, $fullWidth: true);
          width: 100% !important;
          height: 675px !important;
        }

        &__header {
          @include flexProperties(row, center, center, $fullWidth: true);
          width: 100%;
          padding: 0px;
          gap: 50px;
          &__img_title_container {
            @include flexProperties(row, flex-start, center, $fullWidth: true);
            gap: 10px;
            padding: 10px 0px;
            &__img {
              border: 4px solid #4ffcb7;
              border-radius: 50%;
            }
            &__title {
              @include customHeading(24px);
            }
          }
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: false);
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}

.table {
  background: var(--base-container-backgroung-color);
  height: 500px !important;
  max-height: 500px !important;
  color: var(--base-heading-color) !important;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  &__head {
    position: sticky;
    z-index: 1;
    @include textEllipsis();
  }

  &__col {
    background: var(--base-container-backgroung-color) !important;
    text-align: left !important;
    width: 50px !important;
    border-bottom: 1px solid #5a7380 !important;
    @include textEllipsis();
    &__heading {
      @include customTags();
    }

    // &__last {
    //   color: white !important;
    //   background: #2f3443 !important;
    //   text-align: center !important;
    // }

    // &__lastdays {
    //   color: rgb(145, 144, 144) !important;
    //   background: #2f3443 !important;
    //   text-align: center !important;
    //   font-size: 8px;
    // }
  }

  &__row {
    color: #d6d6d6 !important;
    border-bottom: 0px !important;
    // background-color: $base-table-row-background-color;
    padding-left: 20px !important;
    &__name {
      // width: 300px;
      @include customHeading(16px);
      @include textEllipsisLineClamp(1);
      &:hover {
        color: #4ffcb7;
        cursor: pointer;
      }
    }
    &__release_date {
      @include customHeading(16px);
      @include textEllipsisLineClamp(1);
      text-decoration: underline;
    }

    &__own {
      @include customHeading(16px);
      @include textEllipsisLineClamp(1);
    }
    &__genre {
      display: flex;
      font-size: 10px !important;
      gap: 5px;
      &__chip {
        background-color: var(--base-container-backgroung-color) !important;
        color: var(--base-heading-color) !important;
        padding: 15px 5px !important;
        cursor: pointer !important;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}

/* Mobile */
@media screen and (max-width: 360px) {
  /* Your CSS styles for mobile devices with a width up to 360px */
}

@media screen and (min-width: 361px) and (max-width: 375px) {
  /* Your CSS styles for devices with a width between 361px and 375px */
}

@media screen and (min-width: 376px) and (max-width: 414px) {
  /* Your CSS styles for devices with a width between 376px and 414px */
}

@media screen and (min-width: 315px) and (max-width: 599px) {
  /* Your CSS styles for devices with a width between 376px and 414px */
}

/* Tablet */
@media screen and (min-width: 600px) and (max-width: 767px) {
  /* Your CSS styles for tablets with a width between 600px and 767px */
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Your CSS styles for tablets with a width between 768px and 1024px */
}

@media screen and (min-width: 800px) and (max-width: 1279px) {
  /* Your CSS styles for tablets with a width between 800px and 1280px */
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  /* Your CSS styles for tablets with a width between 1024px and 1366px */
}

/* Laptop */
@media screen and (min-width: 1280px) and (max-width: 1366px) {
  /* Your CSS styles for laptops with a width between 1280px and 1366px */
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  /* Your CSS styles for laptops with a width between 1367px and 1920px */
}

/* Desktop */
@media screen and (min-width: 1921px) and (max-width: 2559px) {
  /* Your CSS styles for desktops with a width between 1921px and 2560px */
}

@media screen and (min-width: 2560px) and (max-width: 3840px) {
  /* Your CSS styles for desktops with a width between 2561px and 3840px */
}
