@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  &__resp_grid1 {
    display: none !important;
  }
}

@media only screen and (max-width: 1535px) {
  .page {
    &__resp_grid1 {
      display: block !important;
      margin-top: 10px;
    }
    &__resp_grid2 {
      display: none !important;
    }
  }
}
