@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  &__artist {
    width: 100%;
    &__box {
      background: var(--base-container-backgroung-color);
      border-radius: 12px;
      width: 100%;
      height: 675px !important;
      max-height: 675px !important;
      border: 1px solid var(--base-border-line-color);

      &__topdetails {
        @include flexProperties(row, center, center, $fullWidth: true);
        width: 100% !important;
        height: 20.4% !important;
        padding: 0px 20px 0px 20px;
        gap: 20px;

        &__image {
          height: 89px !important;
          width: 89px !important;
          object-fit: cover;
          border-radius: 50%;
          position: relative;

          .onlineInd {
            position: absolute;
            bottom: 15px;
            right: 0;
            width: 16px;
            height: 16px;
            background-color: var(--picture-border-color);
            border-radius: 50%;
          }
        }

        &__details {
          align-items: center;
          justify-content: center;
          width: 100%;
          // padding-right: 20px;

          &__artistname {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            &__name {
              @include customHeading(1.2rem);
              text-align: left;
              overflow: hidden;
              @include textEllipsis(180px);
            }
            &__icon_container {
              margin-top: 0.5 !important;
              cursor: pointer !important;
              background-color: var(--base-action-icon-background) !important;
              height: 30px !important;
              width: 30px !important;
            }
          }
          &__email {
            @include customParagraph(14px);
            text-decoration: underline;
            text-align: left;
            @include textEllipsis(180px);
          }
          &__country {
            align-items: center;
            // &__flag {
            //   @include customParagraph(14px);
            //   margin-left: 5px !important;
            // }
            &__name {
              margin-left: 5px;
              @include customParagraph(14px);
            }
          }
        }

        &__editicon {
          display: flex;
          flex: 1;
          align-items: flex-start;
        }
      }

      &__horizontalline {
        width: calc(100%) !important;
        height: 1px !important;
        background-color: var(--base-border-line-color);
        position: relative;
      }

      &__listnership {
        @include flexProperties(column, center, flex-start, $fullWidth: true);
        width: 100%;
        min-height: 15.41% !important;
        max-height: 15.41% !important;
        padding: 20px !important;

        &__topheading {
          @include customTags();
        }
        &__innerlyer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          &__text2 {
            @include customHeading(28px);
          }
          &__trending {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            justify-content: center;
          }
          &__text3 {
            font-size: 12px !important;
          }
        }
      }

      &__platforms {
        @include flexProperties(column, center, flex-start, $fullWidth: true);
        width: 100%;
        min-height: 15.26% !important;
        max-height: 15.26% !important ;
        padding: 20px;
        &__text {
          @include customTags();
        }
      }

      &__genre {
        // @include flexProperties(column, center, flex-start, $fullWidth: true);
        width: 100%;
        // overflow-x: scroll;
        // overflow-y: hidden;
        min-height: 13.78% !important;
        max-height: 13.78% !important;
        background: var(--base-container-backgroung-color);
        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }

      &__tracks_info {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        width: 100%;
        min-height: 15.56% !important;
        max-height: 15.56% !important;
        padding: 20px;
        gap: 10px !important;
        &__left {
          width: 30% !important;
          &__text1 {
            @include customTags();
          }
          &__text2 {
            @include customHeading(28px);
          }
        }
        &__right {
          // @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
          // width: 70% !important;
          &__top_details {
            gap: 5px !important;
            overflow: hidden;
            @include customParagraph(11px);
          }
          &__update_botton {
            display: flex;
            width: 100%;
            background: "transparent" !important;
            // background: var(--base-button-background-color) !important;
            border: 2px solid var(--base-button-background-color) !important;
            color: var(--base-button-background-color) !important;
            border-radius: 12px !important;
            font-size: 12px !important;
            font-weight: 700 !important;
            cursor: pointer;
            text-transform: capitalize !important;
            border: none;
            white-space: nowrap;
            height: 40px !important;
          }
        }
      }

      &__buttons_container {
        @include flexProperties(column, center, center, $fullWidth: true);
        width: 100% !important;
        min-height: 17.78% !important;
        max-height: 17.78% !important;
        padding: 0px 20px 0px 20px;
        &__view_funding_dashbord {
          @include activeThemeBotton();
          height: 40px !important;
          margin-bottom: 10px !important;
          &__inner_icon {
            color: var(--base-button-text-color);
          }
        }
        &__edit_dashbord_btn {
          height: 40px !important;
          @include deActiveThemeBotton();
        }
        &__view_funding_dashbord_chip {
          width: 100% !important;
          height: 40px !important;
          @include activeThemeBotton();
          margin-bottom: 10px !important;
        }
      }
      &__loader {
        width: 100%;
        path {
          stroke: var(--base-tags-color) !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              &__name {
                @include customHeading(1.1rem);
                @include textEllipsis(140px);
              }
            }
            &__email {
              @include textEllipsis(140px);
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  .page__artist__box__topdetails__image {
    // width: 70px !important;
    // height: 70px !important;
  }
  .skeleton {
    width: 70px !important;
    height: 70px !important;
  }
  .onlineInd {
    // bottom: 10px !important;
  }
  .page__artist__box__topdetails__details__artistname__name {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: block;
    // width: 110px;
  }
  .page__artist__box__topdetails__details__email {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: block;
    // width: 110px;
  }
}
@media only screen and (max-width: 1535px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              &__name {
                @include customHeading(1.2rem);
                @include textEllipsis(180px);
              }
            }
            &__email {
              @include textEllipsis(180px);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              &__name {
                @include customHeading(1.2rem);
                @include textEllipsis(150px);
              }
            }
            &__email {
              @include textEllipsis(150px);
            }
          }
          &__image {
            height: 80px !important;
            width: 80px !important;
            object-fit: cover;
            border-radius: 50%;
            position: relative;

            .onlineInd {
              bottom: 12px;
              right: 0;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .page {
    &__artist {
      &__box {
        &__tracks_info {
          &__left {
            &__text1 {
            }
            &__text2 {
            }
          }
          &__right {
            &__top_details {
              @include customParagraph(9px);
            }
            &__update_botton {
            }
          }
        }
        // &__tracks_info {
        //   &__container {
        //     &__text2 {
        //       font-size: 24px !important;
        //       font-weight: 700 !important;
        //     }
        //   }
        // }
      }
    }
  }
}
@media (min-width: 630px) and (max-width: 1199px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              &__name {
                @include customHeading(1.1rem);
                @include textEllipsis(100% !important);
              }
            }
            &__email {
              @include textEllipsis(100% !important);
            }
          }
        }
      }
    }
  }
  // .page {
  //   flex-direction: column;
  //   align-items: center;
  //   &__artist {
  //     &__box {
  //       &__tracks_info {
  //         &__container {
  //           &__text2 {
  //             font-size: 28px !important;
  //             font-weight: 700 !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // .page__artist__box__topdetails__details__artistname__name {
  //   width: 100% !important;
  // }
  // .page__artist__box__topdetails__details__email {
  //   width: 100% !important;
  // }
}
@media only screen and (max-width: 1050px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              &__name {
                @include customHeading(1.1rem);
                @include textEllipsis(180px !important);
              }
            }
            &__email {
              @include textEllipsis(180px !important);
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 899px) {
  .page {
    &__artist {
      &__box {
        &__topdetails {
          &__details {
            &__artistname {
              &__name {
                @include customHeading(1.1rem);
                @include textEllipsis(100% !important);
              }
            }
            &__email {
              @include textEllipsis(100% !important);
            }
          }
        }
      }
    }
  }
}
