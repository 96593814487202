@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";

.page {
  &__tabs_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-top: -75px;
    padding: 20px;
  }

  &__stepper_btn_wrapper {
    &__steps {
      border-radius: 10px;
      padding: 8px 8px !important;
      width: 20%;
      text-align: center;
      color: #fff !important;
    }
  }
  &__preview_btn {
    @include activeThemeBotton(); // width: 100%;
    padding: 8px 20px !important ;

    &:hover {
      @include activeThemeBotton(); // width: 100%;
      padding: 8px 20px !important ;
    }
  }

  &__download_btn {
    @include activeThemeBotton();
    padding: 0px 50px !important;

    &:hover {
      @include activeThemeBotton();
    }
  }
  &__return_home_btn {
    @include deActiveThemeBotton();
    padding: 0px 50px !important;
  }
  &__btn_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    &__back_btn {
      border-radius: 12px !important;
      background: transparent !important;
      border: 2px solid var(--base-button-background-color) !important;
      color: var(--base-button-background-color) !important;
      padding: 6px 20px !important;
      font-weight: bold !important;
      width: 100% !important;
    }
    &__save_btn {
      border-radius: 12px !important;
      background: var(--base-button-background-color) !important;
      border: 2px solid var(--base-button-background-color) !important;
      color: var(--base-button-text-color) !important;
      padding: 6px 20px !important;
      font-weight: bold !important;
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 1050px) {
  .page {
    &__tabs_container {
      margin-top: -10px;
    }
  }
}
