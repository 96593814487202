@import "../../styles/colors.scss";

.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  // height: 694px;
  padding: 20px;
  &__topSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__heading {
      color: var(--base-heading-color) !important;
      font-size: 20px !important;
      font-weight: bold !important;
      white-space: nowrap;
    }
  }
  &__tabs_container {
    display: flex;
    gap: 2px;
    border-radius: 15px !important;
    z-index: 10;
    button:nth-child(1) {
      cursor: pointer;
      background-color: var(--base-deactive-button-background-color);
      padding: 10px 30px;
      border-radius: 15px 0px 0px 15px;
      border: none;
      color: var(--base-button-text-color);
      font-size: 12px;
      font-weight: bold;
      transition: all 0.3s ease-in-out;
    }
    button:nth-child(2) {
      cursor: pointer;
      background-color: var(--base-deactive-button-background-color);
      padding: 10px 30px;
    }
  }
}
// @media only screen and (max-width: 1430px) {
//   .page {
//     &__topSec {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       &__heading {
//       }
//     }
//   }
// }
