@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";
.page {
  width: 100%;
  &__table {
    height: 550px !important;

    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
  &__topHeadHeadings {
    @include customTags();
  }
  &__heading1 {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
    // text-align: center;
  }

  &__heading2 {
    @include customHeading(16px);
    font-weight: 700 !important;
  }
  &__actionIcon {
    background-color: #ff3d81 !important;
    width: 30px;
    height: 30px;
    svg {
      fill: #222c41 !important;
      width: auto;
      height: 16px;
    }
    // display: none;
  }
  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
  &__button {
    @include activeThemeBotton();
    margin-top: 20px !important;
  }
  &__notFound {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-align: center;
  }
}
