@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  gap: 0px;
  width: 100%;
  font-family: "DM Sans" !important;
  display: none;

  .chartMainContainer {
    // margin-left: 20px;
    background-color: var(--base-container-backgroung-color);
    // height: 100%;
    min-height: 675px !important;
    max-height: 675px !important;
    // height: 100% !important;
    border-radius: 12px;
    padding: 20px 20px;

    .topHeading {
      @include customHeading(24px);
    }
    .tabContainer {
      width: 100%;
      align-items: center;
    }
    .tabInnerContainer {
      border-bottom: 1px !important;
      border-color: var(--base-border-line-color);
      justify-content: space-evenly;
      @include customParagraph();
    }
  }

  .artistList {
    // margin-left: 20px;
    max-width: 100%;
    background-color: var(--base-container-backgroung-color);
    padding: 20px;
    border-radius: 12px;

    .headAndBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .heading {
        @include customHeading(24px);
      }
      .spotBtn {
        @include activeThemeBotton();
        width: 176px !important;
        padding: 6px 20px;
        white-space: nowrap;
      }
    }

    .allArtist {
      display: flex;
      gap: 5px;
      overflow-x: scroll !important;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .artist {
        @include flexProperties(row, space-between, center, $fullWidth: true);
        white-space: nowrap;
        margin-top: 20px;
        min-width: 400px;
        max-width: 400px;
        height: 129px;
        background-color: var(--base-inner-container-background-color);
        border-radius: 12px;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 2px solid var(--base-inner-container-background-color);
        &:hover {
          border: 2px solid var(--base-button-background-color);
        }
      }
      .details {
        display: flex;
        gap: 20px;
        .artistImg {
          position: relative;
          .avatar {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            border: 4px solid #4ffcb7;
            object-fit: cover;
          }
          .onLine {
            content: "";
            position: absolute;
            // top: 0;
            bottom: 13px;
            right: 5px;
            width: 16px;
            height: 16px;
            background-color: #4ffcb7;
            border-radius: 50%;
          }
        }
        .name {
          @include customHeading(24px);
          cursor: pointer;
          &:hover {
            color: #4ffcb7;
          }
        }
        .email {
          @include customParagraph(14px);
          text-decoration: underline;
        }
        .coutry_continer {
          @include flexProperties(row, flex-start, center, $fullWidth: false);
          @include customParagraph(14px);

          .coutry_flag {
            font-size: 14px !important;
          }
          .coutry_name {
            @include customParagraph(14px);
            margin-left: 5px !important;
          }
        }
      }
      .icon_container {
        margin-top: -50px;
        .icon {
          height: 30px;
          width: 30px;
          background-color: var(--base-button-background-color);
          .innerIcon {
            height: 15px;
            width: 15px;
            color: var(--base-button-text-color);
          }
        }
      }
    }
  }

  &__statusBar {
    @include flexProperties(row, space-between, flex-start, $fullWidth: false);
    width: calc(100% - 250px);

    &__heading {
      font-weight: bold;
      color: #4ffcb7;
    }

    &__icon {
      cursor: pointer;
      width: 25px;
    }
  }

  &__banner {
    background: #222c41;
    width: calc(100%);
    min-height: 17.8rem;
    border-radius: 12px;
    overflow: hidden;
    // padding: 40px;

    &__conatiner {
      @include flexProperties(column, space-between, center, $fullWidth: true);
      // gap: 20px;
      font-family: "DM Sans" !important;
      padding-top: 20px !important;
      &__bg {
        width: 100%;
        // background: $profile-gradient-color !important;
        overflow: hidden;
      }

      &__image {
        &__outer_layer {
          // height: 210px;
          // width: 210px;
          // border-radius: 50%;
          // background: $circle-box-primary-gradient-color;
          @include flexProperties(
            row,
            space-between,
            flex-start,
            $fullWidth: false
          );
          flex: 1;
          border-bottom: #ffffff;
          // border-style: solid;
          border-width: 2px;

          &__artistName {
            color: #ffffff;
            font-size: 24px;
            font-weight: 700;
          }

          &__email {
            color: #ffffff;
            font-size: 14px;
            text-decoration: underline;
          }

          &__country {
            color: #ffffff;
            font-size: 14px;
          }

          &__inner_layer {
            // height: 200px;
            // width: 200px;
            border-radius: 50%;
            border-width: 5px !important;
            border-style: solid;
            border-color: #4ffcb7 !important;
            // background: $circle-box-secondary-gradient-color;
            @include flexProperties(row, center, center, $fullWidth: false);
          }
        }
      }

      &__content {
        &__main_heading {
          font-weight: bold;
          text-transform: capitalize;
          font-size: 32px;
          // letter-spacing: 4px;
          color: #ffffff;
          position: absolute;
          top: 50px;
          // text-shadow: 2px 2px rgb(171, 171, 171);
        }

        &__details {
          margin-bottom: 40px;
          // display: flex;
          flex-wrap: wrap;

          &__box {
            @include flexProperties(row, center, center, $fullWidth: false);
            flex: 1 1 260px;
            gap: 10px;

            &__title {
              font-size: 20px;
              font-weight: bold;
              color: #4ffcb7;
            }

            &__value {
              &__name {
                font-size: 18px;
                font-weight: bold;
                color: #4ffcb7;
              }
              &__username {
                font-size: 16px;
                color: #858997;
                margin-bottom: 10px;
              }

              &__country {
                font-size: 16px;
                color: #4ffcb7;
              }

              &__company {
                font-size: 16px;
                color: #4ffcb7;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  &__main_content {
    width: calc(100%);
  }
  &__tabindicatorcolor {
    color: var(--base-tags-color) !important;
  }
  &__tabindicatorcolorline {
    background-color: #4ffcb7 !important;
  }
}

@media only screen and (max-width: 600px) {
  .page {
    .artistList {
      .headAndBtn {
        .heading {
          @include customHeading(20px);
        }
        .spotBtn {
          @include activeThemeBotton();
          width: 150px !important;
          font-size: 12px !important;
          // white-space: nowrap;
        }
      }
    }
  }
}

// .message {
//   // width: 390px;
//   height: 723px;
//   background-color: #222c41;
//   border-radius: 12px 0px 0px 12px;
//   padding: 30px 20px;
//   .topHeading {
//     color: white;
//     font-size: 24px;
//     font-weight: bold;
//   }
//   .mesContainer {
//     overflow-x: scroll;
//     width: 100%;
//     height: 560px;
//     margin-top: 24px;
//     align-items: center;
//     &::-webkit-scrollbar {
//       display: none;
//     }
//     .mesBox {
//       margin-top: 20px;
//       color: white;
//       border-radius: 12px;
//       // border: 2px solid #4ffcb7;
//       background-color: #192233;
//       padding: 20px 30px;
//       height: 183px !important;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;

//       .imgAndName {
//         display: flex;
//         gap: 10px;
//         .name {
//           font-size: 18px;
//           font-weight: bold;
//           cursor: pointer;
//           &:hover {
//             color: #4ffcb7;
//           }
//         }
//       }
//       .mes {
//         font-size: 14px;
//         display: -webkit-box;
//         -webkit-line-clamp: 2;
//         -webkit-box-orient: vertical;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//       img {
//         width: 36px;
//         height: 36px;
//         border-radius: 50%;
//         border: 2px solid #4ffcb7;
//         object-fit: cover;
//       }
//       .time {
//         font-size: 12px;
//       }
//     }
//   }
//   .viewBtn {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 10px;
//     box-shadow: 0px -23px 53px -3px rgba(34, 44, 65, 0.68);
//     -webkit-box-shadow: 0px -23px 53px -3px rgba(34, 44, 65, 0.68);
//     -moz-box-shadow: 0px -23px 53px -3px rgba(34, 44, 65, 0.68);

//     button {
//       background-color: #4ffcb7 !important;
//       color: #222c41 !important;
//       font-size: 14px !important;
//       font-weight: bold !important;
//       border-radius: 12px;
//       width: 100% !important;
//       box-shadow: 0px -23px 53px -3px rgba(34, 44, 65, 0.68);
//       -webkit-box-shadow: 0px -23px 53px -3px rgba(34, 44, 65, 0.68);
//       -moz-box-shadow: 0px -23px 53px -3px rgba(34, 44, 65, 0.68);
//     }
//   }
// }
