@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  background: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  // height: 100% !important;
  height: 834px !important;
  width: 100% !important;
  padding: 20px !important;
  &__container {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
    width: 100% !important;
    &__header {
      @include flexProperties(row, space-between, center, $fullWidth: false);
      height: 40px !important;
      width: 100% !important;
      margin-bottom: 20px !important;
      &__title {
        @include customHeading(24px);
      }
      &__search {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--base-search-container-background);

        border-radius: 12px;
        padding: 21px 10px 21px 15px;
        height: 40px;

        &__seaarch_icon {
          color: var(--base-search-container-icon-color);
        }
        &__input {
          // background: var(--base-search-container-background);
          background: transparent;
          outline: none;
          color: var(--base-input-text-color);
          border-radius: 12px;
          border: none;
          font-size: 14px;
          margin-left: 10px;
          &::placeholder {
            color: var(--base-heading-color);
            // opacity: 0.5;
          }
        }
      }
    }

    &__tableContainer {
      height: 690px !important;
      &__tableBody {
        overflow-y: auto;
      }
    }
    &__placement {
      @include customHeading(24px);
      @include textEllipsis(150px);
      cursor: pointer;
      :hover {
        color: var(--base-hover-color);
      }
      &__artist_image {
        height: 55px !important;
        width: 55px !important;
      }
    }

    &__country {
      @include customParagraph(14px);
      @include textEllipsis(150px);
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &__email {
      @include customParagraph(14px);
      @include textEllipsis(120px);
      text-decoration: underline;
    }

    &__yearly_earning {
      @include customHeading(18px);
    }

    &__topHeadHeadings {
      @include customTags();
    }

    &__icon {
      svg {
        fill: var(--base-action-icon-color) !important;
        width: auto;
        height: 16px;
      }
    }

    &__loader {
      width: 100%;
      path {
        stroke: var(--base-tags-color) !important;
      }
    }
    &__button {
      @include activeThemeBotton();
      margin-top: 20px !important;
    }
    // &__skeletonLoader {
    //   // -ms-overflow-style: none; /* IE and Edge */
    //   // scrollbar-width: none;
    //   // &::-webkit-scrollbar {
    //   //   display: none;
    //   // }
    // }
    &__notFound {
      @include customHeading(16px);
      font-weight: 700 !important;
      text-align: center;
    }
    &__select_icon {
      color: var(--base-action-icon-color) !important;
      font-size: 25px !important;
      font-weight: bold;
      margin-right: 15px;
      cursor: pointer;
    }
    &__select_items {
      font-size: 12px;
      padding-left: 15px;
      // margin-left: 15px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.wrapper {
  display: block;
  position: relative;
  &:hover {
    .controls {
      display: block !important;
    }
  }

  .controls {
    width: 55px !important;
    height: 55px !important;
    border: 2px solid var(--base-hover-color);
    position: absolute;
    display: none !important;
    background-color: rgba(0, 0, 0, 0.582);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    z-index: 2;
    top: 0;
    text-align: center;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .add {
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      color: var(--base-hover-color);
      cursor: pointer;
      font-size: 25px;
    }
  }
  &__notFound {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-align: center;
  }
}

@media only screen and (max-width: 1100px) {
  .page {
    &__table {
      &__row {
        &__emailMob {
          display: none !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .page {
    &__header {
      &__title {
        @include customHeading(20px);
      }

      &__actionBar {
        width: 40%;

        &__icon {
          left: 20px;
        }

        &__search {
          width: 100%;
        }
      }
    }
    &__table {
      &__row {
        &__placement {
          @include customHeading(20px);
        }
      }
    }
  }
}
