@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  @include flexProperties(row, flex-start, flex-start, $fullWidth: true);
  gap: 0px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__fieldsContainer {
    &__form {
      margin-top: 20px;
      padding-bottom: 40px;
      &__formfield {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__label {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          @include customTags();
        }

        &__dollar {
          position: absolute;
          display: flex;
          bottom: 0;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &__button {
          text-transform: capitalize !important;
          border-radius: 12px !important;
          height: 48px !important;
          background-color: var(--base-button-background-color) !important;
          color: var(--base-button-text-color) !important;
          font-weight: bold !important;
          &:hover {
            background-color: var(--base-button-background-color) !important;
          }

          &__upload {
            margin-top: 5px;
            margin-right: 5px;
          }
          &__disabled {
            text-transform: capitalize !important;
            border-radius: 12px !important;
            height: 48px !important;
            background-color: transparent !important;
            border: 2px dashed var(--base-deactive-button-background-color) !important;
            color: var(--base-deactive-button-background-color) !important;
            font-weight: bold !important;
            &:hover {
              background-color: var(
                --base-deactive-button-background-color
              ) !important;
            }

            &__upload {
              margin-top: 5px;
              margin-right: 5px;
            }
          }
        }

        &__phoneinput {
          border-radius: 12px;
          padding: 0px 20px 0px 20px;
          color: var(--base-input-text-color) !important;
          background-color: var(--base-input-background-color);
          height: 48px !important;
          input {
            color: var(--base-input-text-color) !important;
            background: transparent;
            outline: none;
            font-size: 16px;
            border: none;
          }
        }
        &__address_input {
          border-radius: 12px;
          border: 1px solid var(--base-input-border-color) !important;
          padding: 0px 20px 0px 20px;
          color: var(--base-input-text-color) !important;
          background-color: var(--base-input-background-color);
          height: 48px !important;
          outline: 0;
          transition: all 0.2s ease-in-out;
          font-size: 16px !important;
          &::placeholder {
            font-size: 15px !important;
            color: var(--base-input-text-color) !important;
            opacity: 0.5;
          }
          &:focus {
            border: 1px solid var(--base-input-focus-color) !important;
          }
        }
      }

      &__action {
        gap: 60px;
        @include flexProperties(row, flex-end, center, $fullWidth: true);
        width: 100%;

        &__cancel_btn {
          @include activeThemeBotton();
          height: 40px;
          padding: 5px 10px !important;
        }

        &__submit_btn {
          @include activeThemeBotton();
          height: 40px;
          padding: 5px 10px !important;
        }
      }
    }
  }

  &__loader {
    @include flexProperties(column, center, center, $fullWidth: true);
    width: 100%;
    height: 100%;
  }

  &__details {
    flex: 1;
    width: 100%;

    &__box {
      @include flexProperties(
        column,
        flex-start,
        flex-start,
        $fullWidth: false
      );
      width: 100%;
      gap: 20px;

      border-radius: 12px;
      margin-top: 20px;

      &__adetails {
        &__header {
          &__title {
          }
        }
      }

      &__tracks {
        width: 100%;
        border-radius: 12px;
        padding: 20px;

        &__header {
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .page {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 684px) {
  .page {
    &__artist {
      flex: 1;

      &__box {
        min-height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 434px) {
  .page {
    &__details {
      &__box {
        &__adetails {
          &__content {
            @include flexProperties(
              column,
              flex-start,
              flex-start,
              $fullWidth: false
            );
            gap: 20px;
          }
        }
      }
    }
  }
}

@media (min-width: 630px) and (max-width: 1199.98px) {
  .page {
    flex-direction: column;
    align-items: center;
  }
}
