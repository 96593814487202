@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  &__topSec {
    // @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
    background: #222c41;
    border-radius: 12px;
    border: 1px solid #5a7380;
    min-height: 675px !important;
    max-height: 675px !important;
    width: 100% !important;
    overflow: hidden;

    &__header {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      padding: 20px;
      &__heading {
        color: var(--base-heading-color);
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }

    &__searchContainer {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      width: 100% !important;
      padding: 0px 20px 0px 20px;
      &__search {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--base-search-container-background);
        border-radius: 12px;
        padding: 21px 10px 21px 15px;
        height: 40px;
        width: 100% !important;

        &__input {
          // background: var(--base-search-container-background);
          background: transparent;
          outline: none;
          color: var(--base-input-text-color);
          border-radius: 12px;
          border: none;
          font-size: 14px;
          margin-left: 10px;
          width: 100%;
          &::placeholder {
            color: var(--base-heading-color);
            // opacity: 0.5;
          }
        }

        &__icon {
          color: var(--base-search-container-icon-color);
        }

        &__loading {
          position: absolute;
          top: 50%;
          right: 60px;
          transform: translateY(-50%);
          &__loader {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    &__result {
      width: 100%;
      max-height: 500px;
      // background-color: #5a7380;

      margin-top: 10px;
      border-radius: 12px;
      padding: 20px;
      color: var(--base-paragraph-color);
      overflow-y: auto;

      &__found {
        font-size: 14px;
        font-weight: bold;
        color: var(--base-tags-color);
      }

      &__no_result {
        color: var(--base-tags-color);
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }

      &__details {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 10px;
        padding: 10px;
        cursor: pointer;
        // background-color: var(--base-details-bg-color);
        background-color: var(--base-inner-container-background-color);

        border-radius: 12px;
        &:hover {
          background-color: var(--base-hover-list-color);
        }

        &__avatar {
          border: 2px solid var(--picture-border-color);
          width: 45px;
          height: 45px;
          border-radius: 50%;
        }

        &__name {
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &__country_flag {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 14px;
        }

        &__divider {
          border-bottom: 1px solid var(--base-border-line-color);
          width: 100%;
          margin-top: 10px;
        }
      }

      &__add_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        button {
          width: 100%;
          padding: 12px 20px;
          background-color: var(--base-button-background-color);
          color: var(--base-button-text-color);
          font-size: 16px;
          font-weight: bold;
          border-radius: 12px;
          &:hover {
            background-color: var(--base-button-background-color);
          }
        }

        typography {
          color: var(--base-text-color);
          font-size: 12px;
          text-align: center;
          margin-top: 5px;
        }
      }
    }

    &__noArtists {
      width: 100%;
      max-height: 500px;
      background-color: var(--base-inner-container-background-color);
      margin-top: 10px;
      border-radius: 12px;
      padding: 20px;
      color: var(--base-paragraph-color);
      overflow-y: auto;

      &__no_result {
        color: var(--base-tags-color);
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }

      &__button {
        // width: 100%;
        // padding: 12px 20px;
        // background-color: var(--base-button-background-color);
        // color: var(--base-button-text-color);
        // font-size: 16px;
        // font-weight: bold;
        // border-radius: 12px;
        @include activeThemeBotton();
        &:hover {
          background-color: var(--base-button-background-color);
        }
      }

      typography {
        color: var(--base-text-color);
        font-size: 12px;
        text-align: center;
        margin-top: 5px;
      }
    }

    &__loader {
      width: 100%;
      path {
        stroke: var(--base-tags-color) !important;
      }
    }
  }
}

@media only screen and (max-width: 1750px) {
  .page {
    &__topSec {
      &__tabs_container {
        button {
          white-space: nowrap;
          font-size: 11px !important;
        }
      }
    }
  }
}
