@import "../../../../styles/scss.scss";
@import "../../../../styles/colors.scss";

.page {
  // position: relative;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0px 20px;
    &__heading {
      color: var(--base-heading-color) !important;
      font-size: 24px !important;
      font-weight: bold !important;
    }
    &__header {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;
      // p {
      //   white-space: nowrap;
      // }
      &__seachBar {
        position: relative;
        &__searchIcon {
          color: var(--base-button-background-color);
          position: absolute;
          top: 10px;
          left: 8px;
          z-index: 99;
        }
        &__search {
          border: 2px solid var(--base-border-line-color);
          background-color: #202632;
          padding: 10px 30px 10px 35px;
          border-radius: 10px !important;
          outline: none !important;
          color: var(--base-input-text-color);
          font-size: 14px;

          &::placeholder {
            color: var(--base-heading-color);
            font-size: 14px;
          }
        }
      }
    }
  }
  &__divider {
    border-top: 1px solid var(--base-border-line-color) !important;
    height: 2px !important;
    margin-top: 20px !important;
  }
  &__select_icon {
    color: var(--base-action-icon-color) !important;
    font-size: 25px !important;
    font-weight: bold;
    margin-right: 15px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
  .page {
    &__top {
      flex-direction: column;
      align-items: stretch;
      &__heading {
        font-size: 20px !important;
      }
      &__header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .page {
    &__top {
      flex-direction: column;
      align-items: stretch;
      &__heading {
        font-size: 20px !important;
      }
      &__header {
        display: flex;
        flex-wrap: nowrap;
        p {
          font-size: 10px !important;
          white-space: nowrap;
        }
        &__seachBar {
          &__search {
            width: 100% !important;
          }
        }
      }
    }
  }
}
