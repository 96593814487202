@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  // @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  // width: 100% !important;

  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  &__banner {
    @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
    width: 100% !important;
  }

  // &__main_content {
  //   @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  //   width: 100% !important;
  //   height: 100% !important;
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }
}

// @media only screen and (max-width: 1425px) {
//   .page {
//     &__statusBar {
//       width: 100%;
//     }

//     &__banner {
//       width: 100%;
//     }

//     &__main_content {
//       width: 100%;
//     }
//   }
// }

// @media only screen and (max-width: 767px) {
//   .page {
//     &__statusBar {
//       display: none;
//     }

//     &__banner {
//       &__conatiner {
//         flex-direction: column;

//         &__content {
//           @include flexProperties(
//             column,
//             flex-start,
//             flex-start,
//             $fullWidth: false
//           );
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 578px) {
//   .page {
//     &__banner {
//       &__conatiner {
//         @include flexProperties(
//           column,
//           flex-start,
//           flex-start,
//           $fullWidth: false
//         );
//         height: 100%;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 412px) {
//   .page {
//     &__banner {
//       &__conatiner {
//         &__content {
//           &__title {
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 364px) {
//   .page {
//     &__banner {
//       &__conatiner {
//         &__content {
//           &__title {
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 328px) {
//   .page {
//     &__banner {
//       &__conatiner {
//         &__content {
//           &__title {
//           }
//         }
//       }
//     }
//   }
// }
