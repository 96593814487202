@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 674px;
  padding: 20px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      @include customHeading(24px);
    }

    &__view_all {
      @include activeThemeBotton();
      width: 120px;
    }
  }

  &__tableContainer {
    height: 580px !important;
    margin-top: 15px;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none;

    // &::-webkit-scrollbar {
    //   display: none;
    // }

    &__nameContainer {
      width: 150px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #ffffff !important;
      &__name {
        @include customHeading(16px);
      }
      :hover {
        cursor: pointer;
      }
    }
  }

  &__actionIcon {
    background-color: var(--base-inner-container-background-color) !important;
    width: 30px;
    height: 30px;
    svg {
      fill: var(--base-button-background-color) !important;
    }
  }

  &__heading1 {
    @include customParagraph(16px);
  }
}

@media only screen and (max-width: 460px) {
  .page {
    &__list {
      max-width: 325px;
      &__ul {
        &__li {
          @include flexProperties(row, space-between, center, $fullWidth: true);
          gap: 30px;
          &__name {
            text-align: center;
          }
        }
      }
    }
  }
  .table {
    // &__col {

    // }
    overflow-x: scroll;

    &__row {
      &__sosi {
        width: 100%;
      }
    }
  }
}
