@import "../../styles/scss.scss";
@import "../../styles/colors.scss";
.page {
  width: 100%;
  background-color: var(--base-container-backgroung-color) !important;
  box-shadow: none !important;
  overflow: hidden !important;
  padding: 10px 20px;
  &__placement {
    @include customHeading(14px);
    @include textEllipsis(130px);
    cursor: pointer;
    :hover {
      color: var(--base-hover-color);
    }
    &__avatar {
      height: 45px !important;
      width: 45px !important;
    }
  }
  &__primary_artist {
    color: #fff;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  &__album {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    // position: relative;
    &__avatar {
      width: 40px !important;
      height: 40px !important;
      border-radius: 5px !important;
      object-fit: cover !important;
    }
    &__text {
      @include customHeading(12px);
      @include textEllipsis(100px);
    }
    &__text_date {
      @include customHeading(10px);
    }
    &__top {
      position: absolute;
      background-color: var(--pop-up-background-color);
      width: 190px;
      height: 70px;
      margin-top: -110px;
      z-index: 99;
      border-radius: 8px;
      padding: 5px 10px;
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 20px solid var(--pop-up-background-color);
        z-index: -1;
        top: 60px;

        // margin-top: 30px !important;
        // margin-top: 10px;
      }
      // top: 0;
      // bottom: 30px;
      &__avatar {
        width: 40px !important;
        height: 40px !important;
        border-radius: 5px !important;
        border: 1px solid #fff;
        object-fit: cover !important;
      }
      &__text {
        font-size: 10px !important;
        font-weight: 600 !important;
        color: var(--pop-up-text-color) !important;
        @include textEllipsis();
        &__small {
          font-size: 8px !important;
          font-weight: 600 !important;
          color: var(--pop-up-text-color) !important;
        }
      }

      &__icon {
        width: auto !important;
        height: 10px !important;
        font-size: 15px !important;
        font-size: 700px;
        color: var(--pop-up-text-color) !important;
        margin-right: 50px !important;
        &:hover {
          background: transparent !important;
        }
        svg {
          margin-top: 5px;
        }
      }
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}
.table {
  &__row {
    &__genre {
      display: flex;
      font-size: 10px !important;
      gap: 5px;
      &__chip {
        background-color: var(--base-container-backgroung-color) !important;
        color: var(--base-heading-color) !important;
        padding: 15px 5px !important;
        cursor: pointer !important;
        font-size: 10px !important;
      }
    }

    &__green {
      color: #008000;
    }

    &__red {
      color: #ff1e1e;
    }

    &__icon {
      cursor: pointer;
    }

    &__name {
      display: flex;
      width: 200px;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .actionEdit {
    background-color: var(--base-button-background-color);
    width: 33px;
    height: 33px;
    &:hover {
      background-color: var(--base-button-background-color);
    }
    svg {
      fill: var(--base-action-icon-color);
    }
  }
  .actionDelete {
    background-color: var(--base-action-icon-delete-background);
    width: 33px;
    height: 33px;
    &:hover {
      background-color: var(--base-action-icon-delete-background);
    }
    svg {
      fill: var(--base-action-icon-color);
    }
  }
  .avatar {
    width: 45px !important;
    height: 45px !important;
    cursor: pointer;
  }
}

.modalCss {
  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--base-title-font-color);
    font-size: 20px;
    font-weight: bold;
    background-color: var(--base-title-bg-color);
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: var(--base-container-backgroung-color) !important;
        color: var(--base-icon-color) !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: #222c41;
          color: var(--base-icon-color);
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
