@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  gap: 0px;
  width: 100%;
  font-family: "DM Sans" !important;
  &__topSec {
    &__greeting {
      font-size: 12px !important;
      color: #ff3d81 !important;
      font-weight: 500 !important;
      text-transform: uppercase;
    }
    &__userName {
      font-size: 28px !important;
      color: #ffffff !important;
      font-weight: bold !important;
    }
  }

  &__topHeadHeadings {
    @include customTags();
  }
  &__heading1 {
    @include customHeading(16px);
    text-decoration: underline;
  }

  &__heading2 {
    @include customHeading(16px);
  }
}
