@import "../../styles/colors.scss";
@import "../../styles/scss.scss";
.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  height: 674px;
  padding: 20px;

  &__container {
    width: 100% !important;
    height: 580px !important;
    max-height: 580px !important;

    &__header {
      @include flexProperties(row, space-between, center, $fullWidth: true);
      width: 100%;
      margin-bottom: 18px !important;

      &__title {
        @include customHeading(24px);
      }
      &__search {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: var(--base-search-container-background);
        // background: red;
        border-radius: 12px;
        padding: 21px 10px 21px 15px;
        height: 40px;
        &__seaarch_icon {
          color: var(--base-search-container-icon-color);
        }
        &__input {
          // background: var(--base-search-container-background);
          background: transparent;
          outline: none;
          color: var(--base-input-text-color);
          border-radius: 12px;
          border: none;
          font-size: 14px;
          margin-left: 10px;
          // width: 225px !important;
          &::placeholder {
            color: var(--base-heading-color);
            // opacity: 0.5;
          }
        }
      }
    }

    &__menuItem {
      font-size: 12px !important;
      font-weight: 700 !important;
    }

    &__table {
      height: 505px !important;

      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
    }

    &__topHeadHeadings {
      @include customTags();
    }

    &__name {
      @include textEllipsis(120px);
      @include customHeading(16px);
      font-weight: 700 !important;
      text-decoration: underline;
    }
    &__heading1 {
      @include textEllipsis(100px);
      @include customHeading(16px);
      font-weight: 700 !important;
      text-decoration: underline;
    }
    &__heading2 {
      @include customHeading(16px);
      font-weight: 700 !important;
    }

    &__actionIcon {
      background-color: var(--base-action-icon-background) !important;
      width: 30px;
      height: 30px;
      svg {
        fill: var(--base-action-icon-color);
        width: auto;
        height: 16px;
      }
    }

    &__loader {
      width: 100%;
      path {
        stroke: var(--base-tags-color) !important;
      }
    }

    &__button {
      @include activeThemeBotton();
      margin-top: 20px !important;
    }

    &__notFound {
      @include customHeading(16px);
      font-weight: 700 !important;
      text-align: center;
    }
  }
}
