@import "../../styles/scss.scss";
@import "../../styles/colors.scss";

// .abc {
//   // display: none !important;
//   width: 100%;
//   display: flex !important;
//   flex-direction: column;
//   gap: 20px !important;
// }

.modalbox {
  font-family: "DM Sans";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  box-shadow: 2px 2px 4px #000000;
  padding: 20px;
  color: #ffffff;
  border: none;
  outline: none;

  &__artistbox {
    padding: 20px;
    background-color: #192233;
    border-radius: 10px;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      padding: 20px;
      display: flex;
      align-items: center;
      font-family: "DM Sans";
      font-size: 28px !important;
      font-weight: 700;
    }
  }

  &__artistboxx {
    padding: 20px;
    // background-color: $secondary-bg-color-2;
    border-radius: 10px;
    min-width: 400px;

    &__title {
      padding: 20px;
      display: flex;
      align-items: center;
    }
  }
  &__confirmBtn {
    @include activeThemeBotton();
  }
  &__cancelBtn {
    @include cancelDeactiveBtn();
  }
}

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  width: 100%;
  height: 675px !important;
  max-height: 675px !important;
  background: var(--base-container-backgroung-color);
  border-radius: 12px !important;
  border: 1px solid var(--base-border-line-color);

  &__header {
    // height: 100%;
    // border-bottom: 1px solid rgba(255, 255, 255, .2);
    padding: 20px 0px 0px 10px;

    &__title {
      @include customHeading(24px);
    }
  }

  &__list {
    overflow: auto;
    width: 100%;
    height: 675px !important;
    max-height: 675px !important;
    // padding: 0px 20px 0px 20px;x
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none;
    &__list_continer {
      // display: none !important;
      width: 100%;
      display: flex !important;
      flex-direction: column;
      gap: 20px !important;

      &__list_items {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        gap: 20px !important;
        background-color: var(
          --base-inner-container-background-color
        ) !important;
        border-radius: 10px !important;
        padding: 10px !important;
        margin: 0px 10px 0px 10px;

        &__img_details {
          @include flexProperties(row, center, center, $fullWidth: true);
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__wrapper {
      display: block;
      &:hover {
        .page__list__wrapper__controls {
          display: block;
        }
      }

      &__controls {
        width: 50px !important;
        height: 50px !important;
        border: 2px solid var(--picture-border-color);
        position: absolute;
        display: none;
        background-color: rgba(0, 0, 0, 0.582);
        backdrop-filter: blur(10px);
        border-radius: 50%;
        z-index: 2;
        // top: 10px;
        text-align: center;
        font-size: 0.7rem;

        &__view {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          // border-bottom: 2px solid #4ffcb7;
        }
        &__add {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    &__ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &__li {
        @include flexProperties(row, center, center, $fullWidth: true);
        // gap: 30px;
        // border-bottom: 1px solid rgba(255, 255, 255, .2);
        // padding: 20px;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &__image {
          height: 50px;
          width: 50px;
        }

        &__content {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-left: 10px !important;

          &__name {
            width: 100% !important;
            @include customHeading(16px);
            @include textEllipsisLineClamp(1);
          }
          &__listners {
            @include customTags();
          }

          &__email {
            font-size: 12px;
            color: #4ffcb7;
            @include textEllipsis();
          }
        }
      }
    }

    &__loader {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1535px) {
  .page {
    height: 770px !important;
    max-height: 770px !important;
    &__list {
      height: 680px !important;
      max-height: 680px !important;
    }
  }
}
@media only screen and (max-width: 350px) {
  .page {
    &__list {
      &__ul {
        &__li {
          padding: 13px;

          &__image {
            height: 40px;
            width: 40px;
          }

          &__content {
            &__name {
              font-size: 12px;
            }

            &__email {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
