@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;
  height: 380px;
  max-height: 380px;
  background: #222c41;
  border-radius: 12px;
  border: 1px solid #5a7380;

  &__container {
    width: 100%;
    height: 100% !important;
    padding: 20px;
    // overflow-y: hidden;
    &__topBanner {
      @include flexProperties(column, flex-start, flex-end, $fullWidth: true);
      width: 100%;
    }
    &__chartContainer {
      @include flexProperties(column, center, center, $fullWidth: true);
      width: 100% !important;
      height: 100% !important;
      margin-top: -20px;
    }
  }
  &__loader {
    // background-color: #5a7380;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }
}
