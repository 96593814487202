@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;
  height: 100%;
  padding: 20px 0px 20px 0px;

  &__topHeading {
    @include customTags();
  }

  &__innerContainer {
    @include flexProperties(row, space-between, flex-start, $fullWidth: true);
    width: 100%;
    height: 100%;
    &__rightContainer {
      @include flexProperties(row, flex-start, flex-start, $fullWidth: false);
      width: 60%;
      height: 100%;
      flex-wrap: wrap;
      &__infoContainer {
        @include flexProperties(row, flex-start, flex-start, $fullWidth: false);
        gap: 10px;
        width: 50% !important;
        &__streams {
          font-size: 20px !important;
          font-weight: 100 !important;
          color: #fff;
        }
        &__streamsSecondary {
          font-size: 10px !important;
          font-weight: 400 !important;
          color: #fff;
          white-space: nowrap;
        }
        &__icon {
          height: 16px;
          width: 16px;
          margin-top: 5px;
        }
      }
    }
    &__leftContainer {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      width: 40%;
      height: 100%;
      // background-color: coral;
    }
  }
}

@media only screen and (max-width: 750px) {
  .page {
    &__innerContainer {
      &__rightContainer {
        &__infoContainer {
          &__streams {
            font-size: 16px !important;
          }
        }
      }
    }
  }
}
