@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
  gap: 20px;

  &__statusBar {
    @include flexProperties(row, space-between, flex-start, $fullWidth: false);
    width: calc(100% - 250px);

    &__heading {
      font-weight: bold;
      color: #4ffcb7;
    }

    &__icon {
      cursor: pointer;
      width: 25px;
    }
  }

  &__banner {
    width: calc(100%);
    height: 102px;
    overflow: hidden;

    &__conatiner {
      @include flexProperties(column, flex-start, flex-start, $fullWidth: true);
      gap: 10px !important;
      &__fontSize {
        @include customTags();
      }
      &__bg {
        width: 100%;
        height: 100px;
        overflow: hidden;
      }

      &__chip {
        margin: "5px 0px 5px 0px";
      }
      &__stack {
        margin: "5px 0px 5px 0px";
      }
    }
  }

  &__main_content {
    width: calc(100%);
  }
}
// ? Modal css
.modalCss {
  &__heading {
    background-color: var(--base-title-bg-color);
    color: var(--base-title-font-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    height: 46px;
    border-radius: 12px 12px 0px 0px;
    position: relative;

    &__icon {
      position: absolute;
      right: 10px;
      &__inner {
        background-color: var(--second-button-color) !important;
        color: var(--second-button-border-color) !important;
        font-size: 15px !important;
        font-weight: bold !important;
        &:hover {
          background-color: var(--second-button-color);
          color: var(--second-button-border-color);
        }
      }
    }
  }
  &__button {
    color: #e12020 !important;
    border: 1px solid #e12020 !important;
    border-radius: 12px !important;
    padding: 5px 50px !important;
  }
}
@media only screen and (max-width: 450px) {
  .modalCss {
    width: 90% !important;
  }
}
