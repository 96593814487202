@import "../../../styles/scss.scss";
@import "../../../styles/colors.scss";
.page {
  background-color: var(--base-container-backgroung-color);
  border-radius: 12px;
  border: 1px solid var(--base-border-line-color);
  width: 100%;
  margin-top: 15px;
  .saveBtn {
    @include activeThemeBotton();
  }
  .customize_btn {
    @include activeThemeBotton();
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;

    &__heading {
      color: var(--base-heading-color);
      font-size: 24px;
      font-weight: bold;
    }
  }

  &__divider {
    border-top: 1px solid var(--base-border-line-color) !important;
    height: 2px !important;
  }
  &__container {
    color: var(--base-heading-color);
    padding: 0px 20px;
    &__containerList {
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &__list {
        width: 100%;
        background-color: var(--base-inner-container-background-color);
        border-radius: 12px;
        // padding: 20px 30px;
        &__listContent {
          display: flex;
          justify-content: space-between;
          padding: 20px 30px;
          &__heading {
            font-size: 24px !important;
            color: #fff !important;
            font-weight: bold !important;
            &:hover {
              color: var(--base-tags-color) !important;
            }
          }
          &__listContentIcon {
            background-color: var(--base-action-icon-background) !important;
            color: var(--base-action-icon-color) !important;
            width: 30px;
            height: 30px;
            &:hover {
              background-color: var(--base-action-icon-background) !important;
              color: var(--base-action-icon-color) !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1440px) {
  .page {
    &__top {
      &__heading {
        font-size: 20px !important;
      }
    }
    &__container {
      &__containerList {
        &__list {
          &__listContent {
            &__heading {
              font-size: 20px !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .page {
    &__top {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
}
