@import "../../styles/colors.scss";
@import "../../styles/scss.scss";

.page {
  gap: 0px;
  width: 100%;
  &__tableContainer {
    @include flexProperties(column, center, center, $fullWidth: true);
    background: #222c41;
    border-radius: 12px;
    width: 100%;
    height: 675px !important;
    max-height: 675px !important;
    border: 1px solid #5a7380;

    &__header {
      @include flexProperties(row, space-between, center, $fullWidth: true);
      width: 100%;
      padding: 10px 20px 10px 20px;
      border-bottom: 1px solid #5a7380;
      &__heading {
        @include customHeading(20px);
        font-weight: 700 !important;
      }

      &__button {
        @include activeThemeBotton();
        width: 98px !important;
        height: 32px !important;
      }
    }

    &__table {
      margin-top: 10px !important;
      padding: 0px 20px 0px 20px;
      height: 580px !important;
      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
    }

    &__topHeadHeadings {
      @include customTags();
    }

    &__name {
      @include customHeading(18px);
      font-weight: 900 !important;
    }
    &__heading1 {
      @include customHeading(16px);
      font-weight: 500 !important;
    }
    &__heading2 {
      @include customHeading(12px);
      font-weight: 500 !important;
    }

    &__loader {
      width: 100%;
      path {
        stroke: var(--base-tags-color) !important;
      }
    }

    &__button {
      @include activeThemeBotton();
      font-weight: 900 !important;
    }

    &__notFound {
      @include customHeading(14px);
      font-weight: 900 !important;
      text-align: center;
    }
  }
}
