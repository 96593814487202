@import "../../../styles/colors.scss";
@import "../../../styles/scss.scss";

.page {
  gap: 0px;
  width: 100%;
  font-family: "DM Sans" !important;
  &__topSec {
    &__greeting {
      font-size: 12px !important;
      color: #ff3d81 !important;
      font-weight: 500 !important;
    }
    &__userName {
      font-size: 28px !important;
      color: #ffffff !important;
      font-weight: bold !important;
    }
  }

  &__table {
    margin-top: 10px !important;
    padding: 0px 20px 0px 20px;
    height: 580px !important;

    // -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  &__topHeadHeadings {
    @include customTags();
  }

  &__name {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading1 {
    @include customHeading(16px);
    font-weight: 700 !important;
    text-decoration: underline;
  }
  &__heading2 {
    @include customHeading(16px);
    font-weight: 700 !important;
  }

  &__actionIcon {
    background-color: #ff3d81 !important;
    width: 30px;
    height: 30px;
    svg {
      fill: #222c41;
      width: auto;
      height: 16px;
    }
  }

  &__loader {
    width: 100%;
    path {
      stroke: var(--base-tags-color) !important;
    }
  }

  &__button {
    @include activeThemeBotton();
    font-weight: 900 !important;
  }

  &__notFound {
    @include customHeading(14px);
    font-weight: 900 !important;
    text-align: center;
  }
}
